import React from "react"
import { InputDecimalProps } from "../../../models/bootstrap/IInputDecimalProps"
import NumberFormat from 'react-number-format';
const InputDecimal: React.FC<InputDecimalProps> = (props) => {


    return (
        <div>
            { 
                <div className="form__group" style={props.style}>
                    <NumberFormat 
                     thousandSeparator={props.thousandSeparator} 
                     className={props.className ? props.className : "form__input"}  
                     required={props.requiredField}         
                     ref={props.setRef}          
                     maxLength={props.maxLength}    
                     name={props.name}
                     readOnly={!props.isEditable}
                     decimalScale={props.decimalScale}
                     id={props.id}
                     value={props.value as string}
                    //  onChange={props.onChange}
                     onBlur={props.onBlur}
                     onValueChange={props.onChange}
                     defaultValue={props.defaultValue}
                    
                    />
                    <label htmlFor={props.id} className="form__label">
                        {props.label}
                        {props.requiredField ? <div className="required">*</div> : ""}
                        {props.messageError ? (
                            <div className="required">
                                {props.messageError}
                            </div>
                        ) : (   
                                ""  
                            )}
                    </label>
                </div>
            }
        </div>
    )
}

export default InputDecimal
