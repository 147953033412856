import React, { useEffect } from 'react';
import Chart from 'chart.js'
import {  HorizontalBarChartProps } from '../../../models/bootstrap/IHorizontalBarChartProps';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = (props) => {

    const formatNumber = (value) => {
        const locale = navigator.language;
        return new Intl.NumberFormat(locale, { style: 'decimal' }).format(value)
    }

    useEffect(() => {
        const ctx = document.getElementById(props.id)
        Chart.plugins.register(ChartDataLabels);
 
        let chart:any = null
        if (ctx) {
            chart = new Chart(ctx, {
                type: 'horizontalBar',
                data: {
                    labels: props.labels,
                    datasets: props.datasets
                },              
                options: {
                    onClick: props.handleClick,
                     responsive: true,
                    // maintainAspectRatio: false,
                    plugins: {
                        datalabels: {
                          align: 'start',
                          anchor: 'end',
                          color: function() {
                            return "#FFF"
                          },
                          font: function(context) {
                            var w = context.chart.width;
                            return {
                              size: w < 512 ? 12 : 14,
                              weight: 'bold',
                            };
                          },
                          formatter: function(value, context) {
                            let label = props.showLabelBar  ?  `${value}` : "";
                            if(props.formatLabel == "percent"){
                                label = `${label}%`;
                            }else if(props.formatLabel == "number"){
                                label = formatNumber(label);
                            }else if(props.formatLabel == "currency"){
                              console.log("currency", props.currencySymbol)
                              label = `${props.currencySymbol}${formatNumber(label)}`;
                            }
                            return label;
                          }
                        }
                    },
                    pointLabelFontFamily: "Quadon Extra Bold",
                    scaleFontFamily: "Quadon Extra Bold",
                    animation: {
                        onComplete: function() {

                        }
                     },
                    legend: {
                        display: props.showLegend ? props.showLegend : false,
                        position: 'bottom',
                        labels: {
                          fontColor: "#68778D",
                          usePointStyle: true
                        }
                    },
                    tooltips: {
                        enabled: true,
                        callbacks: {
                            title: function(tooltipItem, data) {
                             let value = data['labels'][tooltipItem[0]['index']];                            
                             if(!value){
                                value =  data['datasets'][tooltipItem[0]['datasetIndex']]["label"]
                             }
                              return  value ? value : "";
                            },
                            label: function(tooltipItem, data) {
                              return data['datasets'][0]['data'][tooltipItem['index']];
                            },
                            afterLabel: function(tooltipItem, data) {
                             // var dataset = data['datasets'][0];
                            //  var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
                              return '';
                            }
                          },
                     },
                    //  hover: {
                    //     animationDuration: 0
                    //  },
                    scales: {
                        xAxes: [
                            {
                                gridLines: {
                                    display: false
                                },
                                categoryPercentage: 1.0,
                                barPercentage: props.barPercentage ? props.barPercentage :  0.4,                        
                                ticks: {
                                    autoSkip: false,
                                    fontSize: 18,
                                    beginAtZero: true,
                                    mirror: true,
                                    fontFamily: "'Open Sans Bold', sans-serif",
                                    display: true  
                                  },
                                  stacked: props.stacked
                            },
                            
                        ],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                min: props.minYValue,
                                max: props.maxYValue,
                                stepSize: props.stepSize
                            }
                        }]
                    }
                }
            });
        }
        props.setRefChart(chart);
    }, [])

    return <canvas id={props.id} width={props.width} height={props.height}></canvas>
}

export default HorizontalBarChart