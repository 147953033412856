import React from "react"
import { CheckBoxProps } from "../../../models/bootstrap/ICheckboxProps"
import '../../../assets/bootstrap/css/checkbox.css'
// React
const Checkbox: React.FC<CheckBoxProps> = (props) => {
    let isEditable =
        props.isEditable !== null && props.isEditable !== undefined
            ? props.isEditable
            : true

    let isTrue = props.value !== null && props.value !== undefined ? props.value : false

    return (
        <div>
            {isEditable ? (
                isTrue ? (
                    <div className="form-check">
                        <input
                            id={props.id}
                            name={props.name}
                            className="form-check-input custom-checkbox"
                            type="checkbox"
                            checked={props.value as boolean}
                            onChange={props.onChange}
                        />
                        <label className="form-check-label" htmlFor={props.id}>
                            {props.label}
                        </label>
                    </div>
                ) : (
                        <div className="form-check">
                            <input
                                id={props.id}
                                name={props.name}
                                className="form-check-input custom-checkbox"
                                type="checkbox"
                                checked={props.value as boolean}
                                onChange={props.onChange}
                            />
                            <label className="form-check-label" htmlFor={props.id}>
                                {props.label}
                            </label>
                        </div>
                    )
            ) : isTrue ? (
                <div className="form-check">
                    <input
                        id={props.id}
                        name={props.name}
                        className="form-check-input custom-checkbox"
                        type="checkbox"
                        checked={props.value as boolean}
                        disabled
                    />
                    <label className="form-check-label" htmlFor={props.id}>
                        {props.label}
                    </label>
                </div>
            ) : (
                        <div className="form-check">
                            <input
                                id={props.id}
                                name={props.name}
                                className="form-check-input custom-checkbox"
                                type="checkbox"
                                checked={props.value as boolean}
                                disabled
                            />
                            <label className="form-check-label" htmlFor={props.id}>
                                {props.label}
                            </label>
                        </div>
                    )}
        </div>
    )
}

export default Checkbox
