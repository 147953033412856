import React, { Fragment, useEffect, useState } from 'react'
import Select from 'react-select'
// import AsyncSelect from 'react-select/async';
import { InputTagProps } from '../../../models/bootstrap/IInputTagProps'

export function InputTag(props: InputTagProps) {
    const [options, setOptions] = useState<{ value: string, label: string, isNew?: boolean }[]>([])
    // const [selectedValues, setSelectedValues] = useState<any>(null)
    const [values, setValues] = useState<any>(undefined) 
    const [id, setId] = useState<any>(null)

    useEffect(() => { 
        setId(props.id);
        if (props.options) setOptions(props.options)
    }, [props.options])

    useEffect(() => {
        if (props.values) setValues(props.values)
    }, [props.values])

    const handleKeyDown = (event) => {  
        if (event.keyCode === 13 || event.keyCode === 9) {
            const { value } = event.target
            event.preventDefault(); 
            let idx = -1;
            if (value == "" || value == null || value == undefined) return
            if (options.length!==0){
                if (options[0].value!==null && options[0].value!==""){
                    idx = options.findIndex(x => x.value.toLowerCase().trim() == value.toLowerCase().trim())
                }
            }
            if (idx == -1) {
                const newElement = { value: value, label: `${value}(New)`, isNew: true }
                setOptions(prevState => [...prevState, newElement])
                setValues(newElement);
            }
        }else if (event.keyCode === 8 || event.keyCode === 46) {
            if (props.isMulti===false){       
                const container = document.querySelector(`#` + id);
                if (container){
                    const matches = container.querySelectorAll(`.select__single-value`);
                    matches.forEach(el => {
                        el.textContent="";
                        setValues("");
                    })
                }
            }
        }
    }

    // const getItem = (values: any) => {
    //     const data = values?.map(value => {
    //         const item = props.options.find((o) => o.value === value)
    //         if (item) {
    //             return item
    //         } else {
    //             return null
    //         }
    //     });
    //     return data
    // }


    return (
        <div>
            <label className="label-control">{props.label}</label>
                    <div>
                        <Select
                            isMulti={props.isMulti}
                            value={values}
                            id={props.id}
                            placeholder={props.placeholder}
                            name={props.name}
                            noOptionsMessage={() => props.noResultMessage || "No options"}
                            onKeyDown={handleKeyDown}
                            isDisabled={props.isDisabled}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            ref={props.setRef}
                            onChange={props.onChange}
                            onInputChange={props.onInputChange}
                            options={options}
                            isClearable={props.isClearable}
                        />                        
                    </div>
        </div>
    )
}

