import React, { Fragment, useEffect, useRef, useState } from "react"
import "tabulator-tables/dist/css/bootstrap/tabulator_bootstrap4.min.css"
import Tabulator from "tabulator-tables" //import Tabulator library
import { TabulatorProps } from "../../../models/bootstrap/ITabulatorProps"
import '../../../assets/bootstrap/css/tabulatorTable.css';
import styled from 'styled-components'
import Checkbox from "../CheckBox";
import moment from "moment";
import Button from '../Button'
import { DropdownButton } from '../DropdownButton'

const TabulatorTable: React.FC<TabulatorProps> = (props) => {
    const [tabulatorInstance, setInstance] = useState<{ instance: any }>({ instance: {} })
    let reference = useRef(null)
    const [showDropdownMenu, setShowDropDownMenu] = useState(false)
    const [menuColumns, setMenucolumns] = useState<Array<any>>([])
    const _search =  (props.Search === false) ? false : true
    const _languages = props.languages ? props.languages : ['1']
    const _columnsCache = getColumnsFromCache(props.id, props.Columns)
    let _columns = _columnsCache ? _columnsCache : props.Columns

    function formatterDate(cell, formatterParams, onRenderedm, type) {
        //cell - the cell component
        //formatterParams - parameters set for the column
        //onRendered - function to call when the formatter has been rendered
        const date = cell.getValue()
        const lang = props.language ? props.language : moment.locale()
        const month = moment(date).lang(lang).format('MMM');
        const day = moment(date).lang(lang).format('DD');
        const year = moment(date).lang(lang).format('YY');
        const time = type == "datetime" ? moment(date).lang(lang).format('hh:mm a') : ""
        return `${day}-${month}-${year} ${time}` //return the contents of the cell;
    }

    function validatorColumns(columns: Array<any>, languages: Array<string>): Array<any> {
        columns.forEach((column) => {
            for (const lang of languages) {
                if (column.field) {
                    if (column.field.toString().toLowerCase().includes("language")) {
                        column.visible = column.field.toString().includes(lang)
                        column.xsHide = column.visible
                        if (column.visible) break
                    }
                }
            }

            if (column.field) {
                if (languages.length == 0 && column.field.toString().toLowerCase().includes("language")) {
                    column.visible = false
                    column.xsHide = false
                } else if (!column.field.toString().toLowerCase().includes("language") && column.visible != false) {
                    column.visible = true
                }
            }

            if (column.sort == "datetime" || column.sort == "date") {
                column.formatter = (cell, formatterParams, onRendered) => formatterDate(cell, formatterParams, onRendered, column.sort)
            }
        })
        return columns
    }

    function getColumnsFromCache(id: string | undefined, columns) {
        try {
            let columnsCache = (window as any).localStorage.getItem(`${id}-tabulator-columns`)
            columnsCache = JSON.parse(columnsCache)
            if (columnsCache.length > 0) {
                columns.forEach(element => {
                    const [col] = columnsCache.filter(x => x.field == element.field)
                    if (col) {
                        element.visible = col.visible
                    }
                })
            }
            return columns
        } catch (error) {
            return null
        }

    }

    function saveColumnsIntoCache(id, columns) {
        (window as any).localStorage.setItem(`${id}-tabulator-columns`, JSON.stringify(columns))
    }


    useEffect(() => {
        _columns = validatorColumns(_columns, _languages)
        let tabulator: any = null; //variable to hold your table
        tabulator = new Tabulator(reference.current, {
            tooltips: props.tooltips ? props.tooltips : true,
            tooltipsHeader: props.tooltipsHeader, //enable header tooltips
            data: props.Data, //link data to table
            columnCalcs: "both",
            columns: _columns, //define table columns
            layout: props.Layout ? props.Layout : "fitColumns",
            height: props.Height ? props.Height : "450px", //Define altura
            pagination: props.Pagination ? props.Pagination : "local",
            paginationSize: props.paginationSize,
            virtualDomHoz: true,
            placeholder: `<span id="${props.id}tabulator">${props.placeholder ? props.placeholder : "Loading..."}</span>`,
            movableRows: props.movableRows,
            groupHeader: props.groupHeader,
            groupStartOpen: props.groupStartOpen,
            groupToggleElement: props.groupToggleElement,
            rowClick: props.RowClick ? props.RowClick : () => {
                console.log("rowClick not implemented")
            },
        })

        if (props.Filter !== null) {
            tabulator.setFilter(props.Filter)
        }

        if (props.GroupBy && props.GroupBy.Key) {
            let startOpen =
                props.GroupBy && props.GroupBy.Collapse
                    ? props.GroupBy.Collapse
                    : false
            tabulator.setGroupStartOpen(!startOpen)
            tabulator.setGroupBy(props.GroupBy.Key)
        }
        const obj = tabulatorInstance
        obj.instance = tabulator
        setInstance({ ...obj })

        if (props.setRefTable) {
            props.setRefTable(tabulator)
        }

        setTimeout(() => {
            if (props.Data.length == 0) {
                try {
                    const element: any = document.getElementById(`${props.id}tabulator`)
                    if (element) element.innerText = props.labelEmptyData
                } catch (error) { }
            }
        }, 5000);

        window.addEventListener('mouseup', function (event: any) {
            const pol: any = document.querySelector('.tabulator__dropdownmenu');
            if (event.target != pol && event.target.parentNode != pol && !event.target.classList.contains('form-check-input')) {
                setShowDropDownMenu(false)
            }
        });
    }, [])

    useEffect(() => {
        _columns = validatorColumns(_columns, _languages)
        const data = _columns.filter(x => x?.type != "check")
        setMenucolumns(data)
        if (tabulatorInstance.instance) {
            tabulatorInstance.instance.setColumns(_columns)
            tabulatorInstance.instance.replaceData(props.Data)
            if (props.Filter) {
                tabulatorInstance.instance.setFilter(props.Filter)
            }
        }

        if (props.Data.length > 0) {
            try {
                const element: any = document.getElementById(`${props.id}tabulator`)
                if (element) element.innerText = props.labelEmptyData ? props.labelEmptyData : "No data available"
            } catch (error) { }
        }
    }, [props])


    const handleOnKeyUpSearch = (e) => {
        const { value } = e.target
        const columns = _columns.map((item) => {
            return { field: item.field, type: "like", value: value }
        })
        const newFilterSearch: any = []
        newFilterSearch.push(columns)
        if (tabulatorInstance.instance) {
            tabulatorInstance.instance.setFilter(newFilterSearch)
        }
    }


    const handleClickDownload = () => {
        //donwload PDF
        if (tabulatorInstance.instance) {
            tabulatorInstance.instance.downloadToTab("pdf", "data.pdf", {
                orientation: "portrait", //set page orientation to portrait
                title: "", //add title to report
            });
        }
    }

    const handleClickMoreOptions = () => {
        setShowDropDownMenu(!showDropdownMenu)
        if (!showDropdownMenu) {
            if (tabulatorInstance.instance) {
                const columns = _columns.filter(x => x.xsHide != false)
                const col: Array<any> = []
                // columns.forEach(co => {
                //     col.push({ name: co._column.definition.field, title: co._column.definition.title, visible: co._column.visible })
                // })
                if(Array.isArray(columns)){
                    setMenucolumns(columns.filter(x => x?.type != "check"))
                }

            }
        }
    }

    const handleOnchangeColumns = (e, index) => {
        const { checked, name } = e.currentTarget
        const col = menuColumns[index]

        col.visible = checked
        if (tabulatorInstance.instance) {
            if (checked) {
                tabulatorInstance.instance.showColumn(name)
            } else {
                tabulatorInstance.instance.hideColumn(name)
            }
        }

        setMenucolumns(menuColumns.filter(x => x?.type != "check"))

        if (props.persistentColumns) {
            saveColumnsIntoCache(props.id, menuColumns)
        }
    }

    return (
        <Fragment>
            {/* {props.Data !== undefined &&
            (props.Data.length > 0 || tabulator !== null) ? ( */}
            {/* <Fragment> */}
            <div className="row">
                <HeaderContainer>
                    <div>
                        <h3 className="title-datatable-custom">
                            {props.Title ? props.Title.label : ""}
                        </h3>
                    </div>

                    <ContainerButtonsAndSearch>
                        <ButtonsContainer>
                            {
                                props.showCreateButton &&
                                (
                                    <Button
                                        id="btn-tabulator-create"
                                        label={props.lblCreateButton || "Create"}
                                        name="btn-tabulator-create"
                                        type="button"
                                        className=""
                                        onClick={props.onClickButtonCreate}
                                        outline={false}
                                        size='md'
                                        color='primary'
                                    />
                                )
                            }
                            {
                                (props.showDropdownButton && props.dropdowButtonProps) && (
                                    <SepareteButton>
                                        <DropdownButton
                                            label={props.dropdowButtonProps.label}
                                            size={props.dropdowButtonProps.size}
                                            color={props.dropdowButtonProps.color}
                                            orderBy={props.dropdowButtonProps.orderBy}
                                            options={props.dropdowButtonProps.options}
                                        />
                                    </SepareteButton>
                                )
                            }

                        </ButtonsContainer>
                        <div className="ml-4">
                            <TabulatorOptions>
                                {_search && (
                                    <div className="col-sm-10">
                                        <div className="input-group filter-datatable-custom">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text input-search" >
                                                    <i className="fas fa-search"></i>
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control input-tabulator"
                                                name={reference + "seachFilterInput"}
                                                id={reference + "seachFilterInput"}
                                                onKeyUp={handleOnKeyUpSearch}
                                                placeholder={props.lblSearch || "Search"}
                                            />
                                        </div>
                                    </div>
                                )}
                                <IconsTabulator>
                                    {
                                        props.columnsIcon &&
                                        <Fragment>
                                            <IconTabulator onClick={handleClickMoreOptions} src="https://cdn-xsalesconsumer.s3.us-east-2.amazonaws.com/console/menu-24px.svg" alt="" />
                                            {
                                                showDropdownMenu &&
                                                <DropDownMenu className="tabulator__dropdownmenu">
                                                    <p>{
                                                        props.labelMenuColumns ? props.labelMenuColumns : "Columns"
                                                    }</p>
                                                    <ListColumns>
                                                        {
                                                            menuColumns.map((obj: any, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <Checkbox
                                                                            id={index.toString()}
                                                                            label={obj.title}
                                                                            name={obj.field}
                                                                            requiredField={true}
                                                                            style={{ width: "450px" }}
                                                                            value={obj.visible}
                                                                            onChange={e => handleOnchangeColumns(e, index)}
                                                                        />

                                                                    </li>)
                                                            })
                                                        }
                                                    </ListColumns>
                                                </DropDownMenu>
                                            }

                                        </Fragment>

                                    }
                                    {
                                        props.downloadIcon &&
                                        <IconTabulator onClick={handleClickDownload} src="https://cdn-xsalesconsumer.s3.us-east-2.amazonaws.com/console/download-24px.svg" alt="" />
                                    }
                                </IconsTabulator>
                            </TabulatorOptions>
                        </div>
                    </ContainerButtonsAndSearch>
                </HeaderContainer>

            </div>
            <div
                ref={reference}
                style={{ marginTop: "10px" }}
                className="table table-sm"
            />

        </Fragment>
    )
}


const TabulatorOptions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position:relative;
`

const IconsTabulator = styled.div`
    width: 60px;
    max-width: 105px;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
`

const IconTabulator = styled.img`
    font-size: 20px;
    color: var(--gray-light-xs-color) !important;
    margin-left: 3px;
    cursor: pointer;
    transition: .05s;
    opacity: .4;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    margin-bottom: 5px;

    &:hover{
        transform: scale(1.1);
    }
`
const DropDownMenu = styled.div`
  position: absolute;
  top: 40px;
  z-index:99999;
  right: 0;
  background: #fff;
  padding: 10px 25px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);;
  border-radius: 5px;
  display: block;
  &:before{
    content: "";
    position: absolute;
    top: -20px;
    left: 40%;
    transform: translateX(120%);
    border: 10px solid;
    border-color: transparent transparent #fff transparent;
  }
`
const ListColumns = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    padding:2px;
`
const HeaderContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`

const ContainerButtonsAndSearch = styled.div`
    display: flex;
    justify-content: space-between;
`

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`
const SepareteButton = styled.div`
    margin-left: 15px;
`



export default TabulatorTable;
