import React from "react"
import { SwitchProps } from "../../../models/bootstrap/ISwitchProps"

const Switch: React.FC<SwitchProps> = (props)  => {
    return (
        <div>
        
                <div className="form-group">
                    <div className="custom-control custom-switch">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id={props.id}
                            name={props.name}
                            checked={props.value}
                            disabled={!props.isEditable}
                            onChange={props.onChange} 
                            onBlur={props.onBlur}
                            onFocus={props.onFocus} 
                            defaultChecked={props.defaultChecked}                        
                            />
                        <label
                            className="custom-control-label"
                            htmlFor={props.id}>
                            {props.label}
                        </label>
                    </div>
                </div>
            
        </div>
    )
}

export default Switch;
