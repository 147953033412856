import React, { Fragment, useState } from "react"
import Select from "react-select"
import { SelectComboProps } from "../../../models/bootstrap/ISelectComboProps"

const SelectCombo: React.FC<SelectComboProps> = (props) => {
    // console.log(`CR props.optionSelected ${props.name}`, props.optionSelected)
    // console.log(`CR props.options ${props.name}`, props.options)
    const isEditable =  props.isEditable
    const [isLoading, setisLoading] = useState(false)

    const handleChange = (e: any) => {
        if (props.onChange) {
            props.onChange(e)
        }
        setisLoading(false)
    }

    const activateLoading = () => {
        setisLoading(true)
    }

    const desactivateLoading = () => {
        setisLoading(false)
    } 

    const getItem = (value: any) => {
        if (value !== undefined && value !== "" && value !== null) {
            const item = Array.isArray(props.options) ? props.options.find((o) => o.value === value): null
            if (item) {
                return item
            } else {
                return null
            }
        }else{
            return null
        }
    }

    const getLabel = (value: any) => {
        if (value !== undefined && value !== "" && value !== null) {
            const item = Array.isArray(props.options) ? props.options.find((o) => o.value === value): null
            if (item) {
                return item.label
            } else {
                return null
            }
        }else{
            return null
        }
    }

    return (
        <div>
            {
                props.messageError ? (
                    <label htmlFor={props.id} className="form__label">
                        {props.label}
                        {props.messageError ? (
                            <div className="required">
                                {props.messageError}
                            </div>
                        ) : (
                            ""
                        )}
                    </label>
                )
                    :    
                        <label className="label-control">{props.label}</label>
                    }
                        <Select
                            ref={props.setRef}
                            id={props.id}  
                            name={props.name}
                            value={getItem(props.value)}
                            defaultValue={getItem(props.optionSelected)}
                            options={props.options}
                            isLoading={isLoading}
                            isSearchable={props.isSearchable}
                            onChange={handleChange}
                            onFocus={activateLoading}
                            onBlur={desactivateLoading}
                            isClearable={props.isClearable}
                            isDisabled={!isEditable}
                            isMulti={props.isMulti}
                        />
       
         
        </div>
    )
}

export default SelectCombo
