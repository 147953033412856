import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from '../Modal'
import { ImageDropzone } from '.'
import { BsPlusCircleFill } from "react-icons/bs"
import { AlertMessage } from '../Message'
import { FileImg } from '../../../models/bootstrap/IImageDropzoneProps'
import { ImageMultipleProps } from '../../../models/bootstrap/IImageMultipleProps'
import { ReplaceImageOptions } from './ReplaceImageOptions'
import ScaleLoader from 'react-spinners/ScaleLoader'


const color = "#5E35B1"

export function ImageMultiDropzone(props: ImageMultipleProps) {
    const [openModal, setOpenModal] = useState(false)
    const [files, setFiles] = useState<{name: string, file: any }[]>([])
    const [loading, setLoading] = useState(false)
    const [filenameReplace, setFileNameReplace] = useState('')
    const [replaceState, setReplaceState] = useState<{
        show: boolean,
        lblCopy: string | undefined,
        lblReplace: string | undefined,
        lblUseExisting: string | undefined,
        file: FileImg | null,
        messageReplace: string | undefined
    }>({
        show: false,
        lblCopy: props?.lblCopy,
        lblReplace: props?.lblReplace,
        lblUseExisting: props?.lblUseExisting,
        file: null,
        messageReplace: ''
    })

    const[ mainImgSrc, setMainImgSrc] =  useState(null)

    useEffect(() => {
        setFiles(props.files)
    },[props.files])

    const width = props.widthImgItem ? props.widthImgItem : "150px"
    const height = props.heightImgItem ? props.heightImgItem : "120px"  

    useEffect(() => {
        setReplaceState(prevState => ({
            ...prevState,
            lblCopy:props?.lblCopy,
            lblReplace: props?.lblReplace,
            messageReplace: props?.messageReplace
        }))
    },[props.lblCopy,props.lblReplace, props.messageReplace])

    const handleClickAdd = (e) => {
        setReplaceState(prevState => ({
            ...prevState,
            show: false,
            file: null,
            lblReplace: '',
            messageReplace: ''
        }))
        const elements = document.querySelectorAll(`.alert`);
        elements.forEach(el => {
            el.setAttribute('style', 'display: none');
        }) 
        setOpenModal(!openModal)
    }
    
    function handleReplaceContainerDismiss(){
        setReplaceState(prevState => ({
            ...prevState,
            show: false,
            file: null
        }))
    }

    async function handleClickCopy(e) {
        setLoading(true)
        const { file } = replaceState
        if (file) {
            debugger
            const updatedFiles = [...files]  // creates a copy of the array            
            if (props.handleUpload) {
                const resultUpload = await props.handleUpload(file,false, true)
                if (resultUpload.isUpload) {
                    // const idx = validateExistsFile(file)
                    // if (idx != -1) {
                    //     updatedFiles[idx] = { ...file }
                    // }
                    
                    const newFile = { name: resultUpload.imageName, file: file  }
                    if(props.isMulti){
                        updatedFiles.push(newFile)
                    }else{
                        if(updatedFiles.length > 0) {
                            updatedFiles[0] = newFile
                        }else{
                            updatedFiles.push(newFile)
                        }
                    }

                    setFiles(updatedFiles)
                }

                setReplaceState(prevState => ({
                    ...prevState,
                    file: null,
                    show: false
                })) 
            }
        }
        setLoading(false)
    }

    function validateExistsFile(file) {
        return files.findIndex(x => x.name.trim().toLowerCase() == file.name.trim().toLowerCase())
    }

    async function handleClickReplace(e) {
        setLoading(true)
        const { file } = replaceState
        if (file) {
            const updatedFiles = [...files]  // creates a copy of the array            
            if (props.handleUpload) {
                const resultUpload = await props.handleUpload(file, true)
                if (resultUpload.isUpload) {
                    // const idx = validateExistsFile(file)
                    // if (idx != -1) {
                    //     updatedFiles[idx] = { ...file }
                    // }
                    const newFile = { name: file.name, file: file }
                    if(props.isMulti){
                        updatedFiles.push(newFile)
                    }else{
                        if(updatedFiles.length > 0) {
                            updatedFiles[0] = newFile
                        }else{
                            updatedFiles.push(newFile)
                        }
                    }
                
                    setFiles(updatedFiles)
                }

                setReplaceState(prevState => ({
                    ...prevState,
                    show: false,
                    file: null
                }))
            }
        }
        setLoading(false)
    }

   async function handleClickUseExisting(e) {
        const { file } = replaceState
        if (file) {
            const updatedFiles = [...files]  // creates a copy of the array            
            if (props.handleUpload) {
                const resultUpload = await props.handleUpload(file, false,false,true)
                if (resultUpload.isUpload) {
                    const idx = validateExistsFile(file)
                    let newFile: any = null
                    if(idx != -1){
                        const newFile = {...updatedFiles[idx]}
                        updatedFiles.push(newFile)
                        setFiles(updatedFiles)
                    }else{
                        // call api
                        const url  = await props.getImageUrl(file.name)
                        newFile = {
                            buffer: new Uint8Array(),
                            lastModified: 0,
                            lastModifiedDate: new Date(),
                            name: file.name,
                            type: file.type,
                            path: '',
                            preview: '',
                            size : 0,
                            webkitRelativePath: '',
                            url: url
                        } 
                    }                   
                    updatedFiles.push(newFile)
                    setFiles(updatedFiles)
                }

                setReplaceState(prevState => ({
                    ...prevState,
                    show: false,
                    file: null
                }))
            }
        }
    }

    const handleDeleteItem = (e, index) => {
        const updatedFiles = [...files] // creates a copy of the array
        updatedFiles.splice(index, 1)
        setFiles(updatedFiles)
        // if (props.handleOnChange) {
        //     props.handleOnChange(updatedFiles)
        // }
    }

    const handleSave = () => {
        setOpenModal(!openModal)
    }

    const handleCancel = () => {
        setOpenModal(!openModal)
    }

    const handleClickSelected = (e, index) => {
        const element = e.target
        if (element.checked) {
            const updatedFiles = [...files] // creates a copy of the array
            const indexElement = updatedFiles[index]
            updatedFiles.splice(index, 1)
            const newFiles = [indexElement, ...updatedFiles]
            setFiles(newFiles)
            // if (props.handleOnChange) {
            //     props.handleOnChange(newFiles)
            // }
        }
    }

    const handleUpload = async (file, index) => {
        let isUpload = false
        const updatedFiles = [...files]  // creates a copy of the array
        if (props.handleUpload) {
            const resultUpload = await props.handleUpload(file)
            if (resultUpload) {
                if (resultUpload.isExists) {
                    setReplaceState(prevState => ({
                        ...prevState,
                        show: true,
                        file: file
                    }))
                    setFileNameReplace(file.name)
                    return false
                }

                isUpload = resultUpload.isUpload
                if(isUpload){
                    updatedFiles[index] = { name: resultUpload.imageName, file: file }
                    setFiles(updatedFiles)
                }
            }
        }
        return isUpload
    }


    useEffect(() => {
        if(props.handleOnChange){
            props.handleOnChange(files)
        }   

        setMainImgSrc(files.length > 0 ? files[0]?.file.src ? files[0].file.src : URL.createObjectURL(files[0].file as any) : null)
    },[files])

   

    return (
        <Fragment>
            {
                <ImageDropzone
                    src={mainImgSrc}
                    isEditable={true}
                    // handleChange={(file) => handleOnChangeFile(file, 0)}
                    // handleUpload={(file) => handleUpload(file, 0)}
                    width={props.width}
                    height={props.height}
                    multiple={true}
                    handleClickAddMultiple={handleClickAdd}
                />
            }

            <Modal
                id="modalMultipleImages"
                title={props.lblTitleModal}
                lblbtnSave={""}
                noAllowSave
                lblbtnCancel={props.lblCloseModal || "CLOSE"}
                handleSave={handleSave}
                handleCancel={handleCancel}
                withOutDismissClose={true}
                open={openModal}
                size='lg'
                submitButton={false}>
                {
                    <ReplaceImageOptions
                        show={replaceState.show}
                        lblCopy={replaceState.lblCopy}
                        lblReplace={replaceState.lblReplace}
                        lblUseExisting={replaceState.lblUseExisting}
                        handleClickCopy={handleClickCopy}
                        handleOnDismiss={handleReplaceContainerDismiss}
                        handleClickReplace={handleClickReplace}
                        handleClickUseExisting={handleClickUseExisting}
                        messageReplace={replaceState.messageReplace}
                        fileName={filenameReplace}
                    />
                }
                <AlertMessage {...props.alertMessage} />
                <WrapperImages>
                    {
                        loading ?
                        <ScaleLoader color={color} loading={true}  css={`display: block;margin: 0 auto;`} />
                        :
                        files.map((file, index) => {
                            const url = file?.file.src ? file.file.src : URL.createObjectURL(file.file)
                            return (<ContainerImages>
                                <ImageDropzone
                                    src={url}
                                    isEditable={true}
                                    // handleChange={(file) => handleOnChangeFile(file, index)}
                                    handleClickSelected={(e) => handleClickSelected(e, index)}
                                    handleUpload={(file) => handleUpload(file, index)}
                                    width={width}
                                    height={height}
                                    handleClickClose={(e) => handleDeleteItem(e, index)}
                                    showCloseIcon={true}
                                    selectedValue={index == 0}
                                />
                            </ContainerImages>)
                        })
                    }
                     {       

                    files.length === 0 && !loading ?
                        <ContainerImages>
                            <ImageDropzone
                                src={""}
                                isEditable={true}
                                // handleChange={(file) => handleOnChangeFile(file, files.length > 0 ? files.length : 0)}
                                handleUpload={(file) => handleUpload(file, files.length > 0 ? files.length : 0)}
                                width={width}
                                height={height}
                                newMode
                            />
                        </ContainerImages>
                        :

                     props.isMulti  &&
                        <ContainerImages>
                            <ImageDropzone
                                src={""}
                                isEditable={true}
                                // handleChange={(file) => handleOnChangeFile(file, files.length > 0 ? files.length : 0)}
                                handleUpload={(file) => handleUpload(file, files.length > 0 ? files.length : 0)}
                                width={width}
                                height={height}
                                newMode
                            />
                        </ContainerImages>
                    
                    }
                  
                </WrapperImages>
            </Modal>
        </Fragment>
    )
}

const WrapperImages = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  
`

const ContainerImages = styled.div`
    margin-right: 15px;
`
