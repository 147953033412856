import React, { useRef } from 'react'
import '../../../assets/bootstrap/css/filters.css'
// import IconFilter from '../../../assets/bootstrap/img/ic_menu_filter.svg'
// import IconFilterSelected from '../../../assets/bootstrap/img/ic_menu_filter_applied.svg'
import { FiltersProps } from '../../../models/bootstrap/IFiltersProps';
let filterslect = 0;
let filterName= "";
const FiltersConsumer: React.FC<FiltersProps> = (props) => {
    const listRef: React.MutableRefObject<null | HTMLUListElement> = useRef(null)
    const imageRef = useRef(null)
    const _classActive = "filter__container__list__buttons__xsales--show"

    const  UnseletedOption = () => {
        if (props.filterName==null){props.filterName = "btn__filters--component";}
        const elements = document.querySelectorAll(`.${props.filterName}`);
        elements.forEach(el => {
            el.classList.remove(`btn__filters__active--component`);
        })
    }

    const handleClickFilter = (e: any, callback: any) => {
        UnseletedOption();
        const element = e.target;
        element.classList.add(`btn__filters__active--component`);
        element.classList.add(`${props.filterName}`);

        if (filterName==element.id){
            filterslect++;
            if (filterslect>=2){ 
                filterslect=0;
                if (props.filterName==null){props.filterName = "btn__filters--component";}
                const elements = document.querySelectorAll(`.${props.filterName}`);

                elements.forEach((el, index) => {
                    if (index == 0) {
                        el.classList.add(`btn__filters__active--component`);
                    }else{
                        el.classList.remove(`btn__filters__active--component`);
                    }
                });
            }
        }else{
            filterName=element.id;
            filterslect++;
        }

        if (callback) {
            // Callback
            callback(e);
        }
    }


    // const checkIfSelectedFilter = () => {
    //     if(listRef.current){
    //         if (listRef.current.classList.contains(_classActive)) {
    //             imageRef.current.src = IconFilterSelected;
    //         } else {
    //             imageRef.current.src = IconFilter;
    //         }
    //     }
    // }

    const handleClickIconFilter = () => {
        if (listRef.current) {
            if (listRef.current.classList.contains(_classActive)) {
                listRef.current.classList.remove(_classActive);
            } else {
                listRef.current.classList.add(_classActive);
            }
        }
        // checkIfSelectedFilter();
    }

    const handleClickClose = () => {
        if (listRef.current) {
            listRef.current.classList.remove(_classActive);
        }
    }

    return (

        <div className="filter__container__buttons">
            <img ref={imageRef} src={"https://cdn-xsalesconsumer.s3.us-east-2.amazonaws.com/console/ic_menu_filter.svg"} className='icon__filter_buttons' onClick={handleClickIconFilter} />

            <ul ref={listRef} className="filter__container__list__buttons__xsales">
                <i className="far fa-times-circle close__icon--filter__container" onClick={handleClickClose}></i>
                {
                    props.data.map((el, index) => {
                        const active = el.active ? `btn__filters--component btn__filters__active--component ${props.filterName}` : `btn__filters--component ${props.filterName}`
                        return (
                            <li key={index} style={{ marginRight: "10px", width: "max-content" }}>
                                <button type={el.type}
                                    onClick={(e: any) => handleClickFilter(e, el.handleClick)}
                                    id={el.id}
                                    name={el.name}
                                    className={active}
                                >
                                    {el.label}
                                </button>
                            </li>
                        )
                    })
                }
            </ul>
        </div>

    )
}

export default FiltersConsumer;
