﻿
import React from 'react'
import confirm from "reactstrap-confirm";
import { ConfirmProps } from '../../../models/bootstrap/IConfirmProps'


 async function Confirm(props:ConfirmProps){
  return await confirm({
      title: (
          <p>{props.title}</p>
      ),
      message:props.message,
      confirmText: props.confirmText,
      cancelText: props.cancelText,
      confirmColor: props.confirmColor,
      cancelColor: props.cancelColor
  });
}
export default Confirm


