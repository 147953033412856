import React from 'react'
import { CardConsumerSummaryProps } from '../../../models/bootstrap/ICardConsumerSummaryprops'
import '../../../assets/bootstrap/css/cardConsumerSummary.css'


const CardConsumerSummary: React.FC<CardConsumerSummaryProps> = (props) => {
    return <div className="card__consumer__summary">
        <img className="card__consumer__summary-img" src={props.image} alt="" />
        <section className="card__consumer__summary__detail">
            <p className="donut__supplier__detail__summary-title">{props.label}:</p>
            <p className="donut__supplier__detail__summary-value">{props.value}</p>
        </section>
    </div>
}

export default CardConsumerSummary;