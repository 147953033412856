import React from 'react'
import Loader from '../Loader'

const LoaderWindow: React.FC<any> = () => {

   return <div style={{ display: "flex", height: "100vh", width: "100%", justifyContent: "center", alignItems: "center" }}>
              <Loader center={true} style={{ height: "120px", width: "120px" }} />
          </div>
}

export default LoaderWindow;