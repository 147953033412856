

import React from 'react'
import styled from 'styled-components'
import { MessagesLogsProps } from '../../../models/bootstrap/IMessagesLogsProps'

export function MessagesLogs(props: MessagesLogsProps) {

    function showIconType(msgType){
        switch (msgType) {
            case "S":
                return  <IconSuccess className="fas fa-check-circle"></IconSuccess>
            case "E":
                return  <IconError className="fas fa-exclamation-circle"></IconError>
            case "W":
                return  <IconWarning className="fas fa-exclamation-triangle"> </IconWarning>      
            default:
                return  <i></i>
        }
    }
    return (
        <Container>
            <TableLogs className="table">
            <Header>
                <tr>
                    <ColumnCode>{props.lblColumnCode ? props.lblColumnCode : "Code"}</ColumnCode>    
                    <ColumnType>{props.lblColumnType ? props.lblColumnType : "Type"}</ColumnType>    
                    <ColumnMessage>{props.lblColumnMessage ? props.lblColumnMessage : "Message"}</ColumnMessage>   
                </tr> 
            </Header> 
            <Body>
            { 
                props.data.map(element => (
                   <tr>
                        <td>{element.msgCode}</td>
                        <td>{showIconType(element.msgType)}</td>
                        <td>{element.message}</td>
                    </tr>
                ))
            }
            </Body>
              </TableLogs>
        </Container>
    )
}


const Container = styled.div``


const TableLogs = styled.table`
    width: 100%;
    border-width: 3px;
    > thead > tr{
       line-height: 10px;
    }

    > tbody > tr {
        line-height: 8px;
    }
`

const Header = styled.thead`
 
    > tr > th{
        font-weight: 500;
        font-size: 16px;
    }
`

const Body = styled.tbody`
  > tr > td{
        font-weight: 400;
        font-size: 14px;
    }
`


const ColumnCode = styled.th`
    width: 5%;
`
const ColumnType = styled.th`
    width: 5%;
`
const ColumnMessage = styled.th`
    width: 90%;
`

const IconSuccess = styled.i`
    color: var(--green-xs-color);
`

const IconWarning = styled.i`
    color: var(--orange-xs-color);
`

const IconError = styled.i`
    color: red;
`


