import React from 'react'

const SeparateTop:React.FC<any> = (props) => {
    return (
        <div style={{marginTop:"1rem"}}>
            {
                props.children
            }
        </div>
    )
}

export default SeparateTop;
