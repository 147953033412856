import React from 'react'
import Utils from '../../../utils/'
import '../../../assets/bootstrap/css/tags.css'
import { TagsProps } from '../../../models/bootstrap/ITagsProps'

function getClassContinaer(size: string ){
    switch (size) {
        case "sm":
            return "tag__container-sm"   
        case "md":
            return "tag__container-md"  
        case "lg":
            return "tag__container-lg"   
        default:
            return "tag__container-md" 
    }
}

const Tags: React.FC<TagsProps> = (props) => {
    const classContainer = getClassContinaer(props.size);
    let classColor = ""
    const backgroundColor: any = {};
    if(!Utils.colors.includes(props.color)){
        backgroundColor.backgroundColor = props.color;
    }else{
        classColor = `tag-${props.color}`;
    }

    return <div className={`tag__container ${classContainer} ${classColor}`} style={backgroundColor}>
                <span className="tag__text">{props.label}</span>
           </div>
}

export default Tags;