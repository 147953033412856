import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js'
import { LineChartProps } from '../../../models/bootstrap/ILineChart';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const LineChart: React.FC<LineChartProps> = (props) => {
    const canvas: React.LegacyRef<HTMLCanvasElement | any> = useRef(undefined);

    useEffect(() => {
      Chart.plugins.register(ChartDataLabels);
        const config =  {
            type: 'line',
            data: {
              labels: props.labels,
              datasets: [{
                label: "",
                backgroundColor: props.pointColor ? props.pointColor : "#2B395A",
                borderColor: props.lineColor ? props.lineColor : "#FFF",
                data: props.data,
                fill: false,
                pointRadius: 5,
                pointHoverRadius: 5
              }]
            },
            options: {
              plugins:{
                datalabels: {
                  align: 'start',
                  anchor: 'end',
                  color: function() {
                    return "#FFF"//context.dataset.backgroundColor;
                  },
                  font: function(context) {
                    var w = context.chart.width;
                    return {
                      size: w < 512 ? 12 : 14,
                      weight: 'bold',
                    };
                  },
                  formatter: function(value, context) {
                     const percent = props.showLabel  ? `${context.chart.data.datasets[0].data[context.dataIndex]}%` : "";
                    return percent;
                  }
                }
            },
                cornerRadius: 50    ,   
                elements: {
                    line: {
                        tension: 0
                    }
                },
              responsive: true,
              title: {
                display: false,
                text: 'Chart.js Line Chart'
              },
              tooltips: {
                mode: 'label',
              },
              hover: {
                mode: 'nearest',
                intersect: true
              },
              legend: {
                position: 'bottom',
                display: false
              },
              scales: {
                xAxes: [{
                    gridLines: {
                        display: false,
                        color: props.labelColor ? props.labelColor : "#FFF"
                    },
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: ''
                  },
                  ticks: {
                    fontColor: "#FFF",
                    fontSize: 18,
                    stepSize: 1,
                    beginAtZero: true
                }
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        color: props.labelColor ? props.labelColor : "#FFF"
                    },
                  display: true,
                  scaleLabel: {
                    display: false,
                    labelString: 'Value'
                  },
                  ticks: {
                    fontColor: "#FFF",
                    fontSize: 14,
                    min: props.minValue,
                    max: props.maxValue,
                    stepSize: props.stepSize,
                    beginAtZero: true
                }
                }]
              }
            }
          };

        const chart = new Chart(canvas.current.getContext('2d'), config);
        canvas.current.style.backgroundColor = props.backgroundColor ? props.backgroundColor : '#9A67CB';
        if(props.setRefChart){
            props.setRefChart(chart);
        }
        return () => chart.destroy();
    },[])

    return <canvas ref={canvas} style={{borderRadius:"12px", padding:"17px"}} width={props.width} height={props.height} className="chartjs"></canvas>
}

export default LineChart