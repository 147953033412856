import React from "react"
import { InputTextProps } from "../../../models/bootstrap/IInputTextProps"

const InputText: React.FC<InputTextProps> = (props) => {


    const isEditable =
        props.isEditable !== null && props.isEditable !== undefined
            ? props.isEditable
            : true

    return (
        <div>
            {isEditable ? (
                <div className="form__group" style={props.style}>
                    <input
                        type="text"
                        className={props.className ? props.className : "form__input"}  
                        required={props.requiredField}         
                        ref={props.setRef}          
                        maxLength={props.maxLength}    
                        name={props.name}
                        id={props.id}
                        value={props.value as string}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        defaultValue={props.defaultValue}
                    />
                    <label htmlFor={props.id} className="form__label">
                        {props.label}
                        {props.requiredField ? <div className="required">*</div> : ""}
                        {props.messageError ? (
                            <div className="required">
                                {props.messageError}
                            </div>
                        ) : (   
                                ""  
                            )}
                    </label>
                </div>
            ) : (
                    <div className="form__group" style={props.style}>
                        <input
                            type="text"
                            ref={props.setRef}
                            maxLength={props.maxLength}
                            className={ props.className ? `${props.className} form__input_readonly form__readonly` : `form__input_readonly form__readonly`}
                            required={props.requiredField}
                            value={props.value as string}
                            onBlur={props.onBlur}
                            name={props.name}
                            id={props.id}
                            onChange={props.onChange}
                            defaultValue={props.defaultValue}
                            readOnly
                        />
                        <label htmlFor={props.id} className="form__label__readonly">
                            {props.label}
                            {props.requiredField ? <div className="required">*</div> : ""}
                            {props.messageError ? (
                                <div className="required">
                                    {props.messageError}
                                </div>
                            ) : (
                                    ""
                                )}
                        </label>
                    </div>
                )}
        </div>
    )
}

export default InputText
