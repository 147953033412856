import React from 'react'

const ToRight: React.FC<any> = (props) => {
    return (
        <div style={{float:"right"}}>
            {props.children}
        </div>
    )
}

export default ToRight
