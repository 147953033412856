import React from "react"
import { ButtonProps } from "../../../models/bootstrap/IButtonProps"
import "../../../assets/bootstrap/css/button.css"
import { BsFillPersonPlusFill } from "react-icons/bs";
import styled from 'styled-components'

const Button: React.FC<ButtonProps> = (props) => {
    const defaultBtn = `btn__${props.size} btn__${props.color ? props.color : "primary"}`
    const outlineBtn = `btn__outline-${props.size} btn__outline-${props.color ? props.color : "primary"}`
    const className = props.className ? `${props.className}  ${props.outline ? outlineBtn : defaultBtn}` : props.outline ? outlineBtn : defaultBtn
    const handleKeypress = (e: any) => {
        //it triggers by pressing the enter key
        if (props.onKeyPress) {
            if (e.keyCode === 13) {
                props.onKeyPress(e)
            }
        } else {
            console.log("Se presiono pero no se activo")
        }
    }

    return (
        <button
            type={props.type ? props.type : "button"}
            className={className}
            name={props.name}
            id={props.id}
            onClick={props.onClick}
            form={props.form}
            onBlur={props.onBlur}
            onKeyPress={handleKeypress}
            data-toggle={props.dataToggle}
            data-target={props.dataTarget ? "#" + props.dataTarget : ""}
            data-dismiss={props.dataDismiss || ""}>
            <ContentButton>
               <Label>{props.label}</Label>
            </ContentButton>
        </button>
    )
}

const ContentButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
const Label = styled.span`
    margin-left: 5px;
`

export default Button
