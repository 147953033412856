import React from 'react'
import { TabCellProps, TabProps } from '../../../models/bootstrap/ITabProps';
import '../../../assets/bootstrap/css/tab.css'

const Tab:React.FC<TabProps> = (props) => {

    const deleteActiveOption = () => {
      const elements = document.querySelectorAll(`.tap__active`);
        elements.forEach(el => {
            el.classList.remove("tap__active");
            el.classList.add("tap__inactive")
        })
    }

    const handleClickTap = (e:any) => {
      const element = e.currentTarget

       document.querySelectorAll<HTMLElement>(".tabCell").forEach(function(item, index, array) {
              item.style.display="none";
              item.classList.remove( "show");
         });

      const tabCell = document.getElementById(`div-${element.id}`) as HTMLElement
      tabCell.style.display="block";
      tabCell.classList.add( "show");

      if (!element.classList.contains("tap__active")) {
            deleteActiveOption();
            element.classList.add("tap__active");
        }
    }

    return (
    <div>
        <ul className="nav nav-tabs" id="custom-content-above-tab" role="tablist">
            {
                props.options.map((el) => {
                    const className = el.default ? "nav-link active tap__active" : "nav-link tap__inactive"
                    return (
                        <li className="nav-item">
                            <a className={className} onClick={handleClickTap} id={`${el.id}-tap`} data-toggle="pill" href={`#${el.id}`} role="tab" aria-controls={`${el.id}`} aria-selected="false">{el.label}</a>
                        </li>
                    )
                })
            }

        </ul>
        <div className="tab-content" id="custom-content-above-tabContent">
            {
                props.children
            }

        </div>
    </div>
    )
}


const TabCell:React.FC<TabCellProps> = (props) => {
    const className =  props.show ? "tabCell tab-pane fade active show" : "tabCell tab-pane fade"
    return (
            <div className={className} id={`div-${props.id}-tap`} role="tabpanel" aria-labelledby={`${props.id}-tap`}>
                {
                    props.children
                }
            </div>
    )
}

export {
    Tab,
    TabCell
}
