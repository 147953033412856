import React, { useState, useEffect, Fragment } from 'react'
import { Alert } from 'react-bootstrap'
import styled from 'styled-components'
import Button from '../Button'
import { ReplaceImageProps } from '../../../models/bootstrap/IReplaceImageProps'



export function ReplaceImageOptions(props: ReplaceImageProps) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show)
    }, [props.show])

    const handleOnDismiss = (e) => {
        if(props.handleOnDismiss){
            props.handleOnDismiss(e)
        }
        setShow(!show)
    }

    if (show) {
        return( 
            <AlertStyled bsStyle={"info"} onDismiss={handleOnDismiss}>    
            {
                props.messageReplace &&
                <p>{props.messageReplace}</p>
            } 
            {
                props.fileName &&
                <p><strong>{props.fileName}</strong></p>
            } 
            <Container>
                <Button
                        label={props.lblCopy || "Upload a Copy"}
                        name="copyImageBtn"
                        type="button"
                        className=""
                        outline
                        onClick={props.handleClickCopy}
                        size='sm'
                        color='default'
                />
                {/* <Button
                        label={props.lblUseExisting || "Use Existing Image"}
                        name="useImageBtn"
                        type="button"
                        className=""
                        outline
                        onClick={props.handleClickUseExisting}
                        size='sm'
                        color='default'
                /> */}
                 <Button
                        label={props.lblReplace || "Replace"}
                        name="replaceImageBtn"
                        type="button"
                        className=""
                        outline
                        onClick={props.handleClickReplace}
                        size='sm'
                        color='default'
                />
            </Container>
            </AlertStyled>
        )
       }
       return <Fragment></Fragment>
}

const AlertStyled = styled(Alert)`
    background-color: #fff;
    color: inherit;
`

const Container = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`