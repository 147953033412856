import React, {useEffect, useState} from 'react'
import { ImageProps } from '../../../models/bootstrap/IImageProps'


const Image: React.FC<ImageProps> = (props) => {
    const[isInvalidUrl, setIsInvalidUrl] = useState(false)
    function validURL(str: string): boolean {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
      }

    useEffect(() =>{
        setIsInvalidUrl(validURL(props.src)  ? false : true)
    },[props.src])
    
    return (
        <figure style={{ position: "relative" }}>
            <div style={{display:"flex"}}>
                <img
                    src={props.src}
                    data-value={props.dataAttrValue}
                    onError={props.OnError}
                    className={props.className}
                    alt={props.alt}
                />
                {
                    isInvalidUrl &&
                    <i className="fas fa-exclamation-triangle" style={{marginLeft:"30px", color:"red"}}></i>
                }
            </div>
            <figcaption style={{ marginTop: "10px" }}>{props.figcaption}</figcaption>
        </figure>
    )
}


export default Image