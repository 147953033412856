import React from "react"
import { CheckStatusProps } from '../../../models/bootstrap/ICheckStatusProps'
import '../../../assets/bootstrap/css/checkStatus.css'

const CheckStatus:React.FC<CheckStatusProps> = (props) => {
    return (
        <div>
            {props.condition === true ? (
            
                    <div className="checkstatus__wrapper">
                         <i className="fas fa-check-circle checkstatus__wrapper__icon-green"></i>
                         <span className="auto-ajust">{props.label}</span>
                    </div>
                   
            
            ) : (
                 
                    <div className="checkstatus__wrapper">
                    <i className="fas fa-exclamation-circle checkstatus__wrapper__icon-red"></i>
                        <span className="auto-ajust">{props.label}</span>
                    </div>
                
            )}
        </div>
    )
}

export default CheckStatus
