import React, { useState, useEffect, Fragment } from 'react'
import '../../../assets/bootstrap/css/checkbox.css'
import { CarrouselProps } from "../../../models/bootstrap/ICarrouselProps"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick'
import styled from 'styled-components'
import moment from 'moment'
import Loader from '../Loader'
// React
const Carrousel: React.FC<CarrouselProps> = ({ dataBanners , lblactive, lblinactive, lblsince, lbluntil, width, height  }) => {
    const [currentBanner, setCurrentBanner] = useState({
        imageUrl: "",
        description: "",
        active: false,
        since: "",
        until: "",
        width: "auto",
        height: "auto"
    })
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slideToScroll: 1,
        autoplay: false,
        beforeChange: handleBeforeChange,
        afterChange: handleAfterChange

    }

    function handleBeforeChange(current, next) {
        console.log(next)
        console.log(current)
        const obj: any = dataBanners[next]
        setCurrentBanner(obj)
    }

    function handleAfterChange(current) {
        console.log(current)
    }

    useEffect(() => {
        if (dataBanners.length > 0) {
            setCurrentBanner(dataBanners[0] as any)
        }
    }, [dataBanners])

    return (
        <Container>
            {
                dataBanners.length == 0 ?
                    <Loader center={true} style={{ width: "70px", height: "70px", margin: "0 auto" }} />
                    :
                    <Fragment>
                        <Carousel {...settings}>
                            {
                                dataBanners.map((banner) => {
                                    return (
                                        <Wrap>
                                            <img src={banner.imageUrl} width={width} height = {height}/>
                                        </Wrap>
                                    )
                                })
                            }
                        </Carousel>
                        <Active>{currentBanner.active ? lblactive : lblinactive}</Active>
                        <Description>{currentBanner.description}</Description>
                        {
                            (lblsince && lbluntil) &&
                            <Description>{`${lblsince} ${moment(currentBanner.since).format("L")} ${lbluntil} ${moment(currentBanner.until).format("L")}`}</Description>
                        }
                    </Fragment>
            }
        </Container>
    )
}


export default Carrousel

const Active = styled.p`
    margin-top: 35px;
    text-align: center;
`
const Description = styled.p`
    text-align:center;
`

const Container = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 0;
    width:60vw;
    position: relative;
`

const Wrap = styled.div`
    display: flex;
    justify-content: center;

    img{
        border: 4px solid transparent;
        border-radius: 4px;
        margin: 0 auto;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 70%) 0px 16px 10px -10px;
        transition-duration: 300ms;
    }
`


const Carousel = styled(Slider)`
    margin-top: 20px;
    ul li button{
        &:before{
            font-size: 10px;
            color: rgb(150, 158, 171);
        }
    }

    li.slick-active button:before {
        color: #5E35B1;
    }

    .slick-list{

    }

    .slick-prev:before, .slick-next:before {
        color: #5E35B1;
    }

    button {
        z-index: 1;
    }
`

