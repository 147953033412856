﻿import React from "react"
import styled from 'styled-components'

export default function Warning(props) {
    return (
        <WarningStyled  role="alert">
            {props.children}
        </WarningStyled>
    )
}

const WarningStyled = styled.div`
    color: #1F2D3D;
    background: #ffc107;
    border-color: #edb100;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin: 10px;
`
