import React, { useEffect, Fragment } from "react"
import { TimePickerProps } from "../../../models/bootstrap/ITimePicker"
import moment from 'moment'

const TimePicker: React.FC<TimePickerProps> = (props) => {

    useEffect(() => {
        try {
            (window as any).$(`#timepicker-${props.id}`).datetimepicker({
                defaultDate: props.value == "" ? "" : moment(props.value,'HH:mm'),
                format: 'HH:mm',
                pickDate: false,
                pickSeconds: false,
                pick12HourFormat: false  
            })
     
            (window as any).$(`#timepicker-${props.id}`).on("change.datetimepicker",props.onChange)           
        } catch (error) {
            (window as any).$(`#timepicker-${props.id}`).on("change.datetimepicker",props.onChange)
            //console.log(error)
        }
       
    }, [props.isEditable])


    const isEditable =
        props.isEditable !== null && props.isEditable !== undefined
            ? props.isEditable
            : true


    const handleClickClock = (e) => {
        if(props.handleClickClock){
            props.handleClickClock(e);
        }
    }

    return (
        <div>
            {isEditable ? (
                <Fragment>
                    <div className="form__group">
                        <div className="input-group date"
                            id={`timepicker-${props.id}`}
                            data-target-input="nearest" style={{ height: props.height ? props.height : "47px", width: props.width ? props.width : "8rem", position: "relative" }}>
                            <input type="text"
                                ref={props.ref}
                                className="form__input datetimepicker-input"
                                data-target={`#timepicker-${props.id}`}
                                name={props.name}
                                required={props.requiredField}
                                id={props.id}
                                defaultValue={props.value}
                               // onChange={props.onChange}
                                onBlur={props.onBlur}
                            />
                            <div className="input-group-append" style={{ top: "2px", right: "50px", cursor: "pointer", position: "absolute" }} data-target={`#timepicker-${props.id}`} data-toggle="datetimepicker">
                                <div className="input-group-text"><i className="far fa-clock" onClick={handleClickClock}></i></div>
                            </div>
                        </div>
                        <label className="form__label">
                            {props.label}
                            {props.required ? <div className="required">*</div> : ""}
                            {props.messageError ? (
                                <div style={{ fontFamily: "cursive" }} className="required">
                                    {props.messageError}
                                </div>
                            ) : (
                                ""
                            )}
                        </label>
                    </div>
                </Fragment>
            ) : (
                <div className="form__group" style={{ height: props.height ? props.height : "47px", width: props.width ? props.width : "8rem" }}>
                    <input
                        type="text"
                        ref={props.ref}
                        maxLength={props.maxLength ? props.maxLength : 12}
                        className={
                            props.className ? `${props.className} form__input_readonly form__readonly` : `form__input_readonly form__readonly`
                        }
                        name={props.name}
                        required={props.requiredField}
                        id={props.id}
                        defaultValue={props.value}
                     //   onChange={props.onChange}
                        onBlur={props.onBlur}
                        readOnly
                    />
                    <label htmlFor={props.id} className="form__label__readonly">
                        {props.label}
                        {props.required ? <div className="required">*</div> : ""}
                        {props.messageError ? (
                            <div style={{ fontFamily: "cursive" }} className="required">
                                {props.messageError}
                            </div>
                        ) : (
                            ""
                        )}
                    </label>
                </div>
            )}
        </div>
    )
}

export default TimePicker

