import React, { useState } from "react"
import Select from "react-select"
import { AutocompleteProps } from "../../../models/bootstrap/IAutocompleteProps"
import '../../../assets/bootstrap/css/select.css'


const AutoComplete: React.FC<AutocompleteProps> = (props) => {
    if (props.isEditable == undefined || props.isEditable == null) {
        throw new Error("You must define isEditable props for AutoComplete")
    }

    const [isLoading, setisLoading] = useState(false)

    const handleChange = (e: any) => {
        if (props.onChange) {
            props.onChange(e)
        }
        setisLoading(false)
    }

    const activateLoading = () => {
        setisLoading(true)
    }

    const desactivateLoading = () => {
        setisLoading(false)
    }

    return (
        <React.Fragment>
            <label className="label-control">
                {props.label}
                {props.required ? <div className="required">*</div> : ""}
            </label>
            {props.isEditable ? (
                <Select
                    id={props.id}
                    name={props.name}
                    defaultValue={
                        props.defaultValue !== undefined
                            ? props.defaultValue
                            : props.options[0]
                    }
                    options={props.options}
                    isLoading={isLoading}
                    isSearchable={true}
                    onChange={handleChange}
                    onFocus={activateLoading}
                    onBlur={desactivateLoading}
                    isDisabled={!props.isEditable}
                />
            ) : (
                    <label className="form-control-readonly">
                        {props.value}
                    </label>
                )}
        </React.Fragment>
    )
}

export default AutoComplete;
