import React from "react"
import { TextAreaProps } from "../../../models/bootstrap/ITextAreaProps"

const TextArea: React.FC<TextAreaProps> = (props) => {
    let isEditable =
        props.isEditable !== null && props.isEditable !== undefined
            ? props.isEditable
            : true

    return (
        <div className="form__group" style={props.style}>

            {isEditable ? (
                <React.Fragment>
                    <textarea
                        ref={props.setRef}
                        className="form__textarea"
                        rows={ props.rows ? props.rows : 2 }
                        value={props.value as string}
                        required={props.requiredField}
                        data-value={props.dataAttrValue}
                        onChange={props.onChange}
                        id={props.id}
                        name={props.name}
                        //defaultValue={props.defaultValue}
                    />
                    <label className="form__label">
                        {props.label}
                        {props.requiredField ? <div className="required">*</div> : ""}
                    </label>
                </React.Fragment>
            ) : (
                    <React.Fragment>
                        {/* <label className="form-control-readonly" name={props.name}>
                            {props.value}
                        </label> */}
                        <textarea
                            ref={props.setRef}
                            className={props.className ? `${props.className } form__textarea_readonly form__readonly` :  `form__textarea_readonly form__readonly` }
                            rows={
                                props.rows !== undefined && props.rows !== null
                                    ? props.rows
                                    : 2
                            }
                            onChange={props.onChange}
                            id={props.id}
                            defaultValue={props.value as string}
                            name={props.name}
                            required={props.requiredField}
                            data-value={props.dataAttrValue}
                            value={props.value}
                            readOnly
                        />
                        <label className="form__label__readonly">
                            {props.label}
                            {props.requiredField ? <div className="required">*</div> : ""}
                        </label>
                    </React.Fragment>
                )}
        </div>
    )
}

export default TextArea
