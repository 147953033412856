import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { BsPencilSquare, BsTrash, BsFillXCircleFill, BsPlusCircleFill } from "react-icons/bs"
import { FileImg, ImageDropzoneProps } from '../../../models/bootstrap/IImageDropzoneProps'
import ScaleLoader from 'react-spinners/ScaleLoader'

const color = "#5E35B1"

export const ImageDropzone: React.FC<ImageDropzoneProps> = (props) => {
    const [files, setFiles] = useState<Array<any>>([]);
    const [file, setFile] = useState({});
    const [loading, setloading] = useState<boolean>(false)
    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        accept: "image/*"
    });

    useEffect(() => {
        const _files: any = acceptedFiles.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file)
            })
        )
        setloading(true)
        _files.forEach((file: any, index: any) => {
            const reader = new FileReader();
            reader.addEventListener('load', async () => {
                const data = file as FileImg;
                data.buffer = new Uint8Array(reader.result as ArrayBuffer);
                // console.log(data)
                if (props.handleChange) {
                    props.handleChange(data)
                    
                }

                if (props.handleUpload) {       
                    const isUpload = await props.handleUpload(data)
                    if(!isUpload){
                        setloading(false)
                        return
                    }
                }

                setloading(false)
                setFile({ ...data })
                setFiles(_files);
                // call api here 
            });
            reader.readAsArrayBuffer(file);
        })
    }, [acceptedFiles])

    const thumbs = files.map((file: any, index: any) => {
        return (
            <ImageThumb key={index} onClick={() => open()}>
                <Image src={file.preview} alt="Image not found" />
            </ImageThumb>
        )
    });

    async function getFileFromUrl(url, name, defaultType = 'image/jpeg') {
        const headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        // headers.append('Accept', 'application/json');
        headers.append('Access-Control-Allow-Origin', '*');
        // headers.append('Access-Control-Allow-Credentials', 'true');
        headers.append("Access-Control-Allow-Headers", "X-Requested-With");
        const proxy = "https://app.xsalesmaps.com/proxy/"

        const response = await fetch(`${proxy}${url}`, {
            // mode: 'cors',
            headers
        });
        const data = await response.blob();
        return new File([data], name, {
            type: response.headers.get('content-type') || defaultType,
        });
    }

    const parseImageFromUrl = async () => {
        setloading(true)
        try {
            if (props.src) {
                const src = props.src
                const data: any = await getFileFromUrl(src, "imageSrc")
                data.preview = src
                if (data) setFiles(new Array(data))
            } else {
                setFiles([])
            }

        } catch (error) {
            console.error(error)
        }
        setloading(false)
    }

    useEffect(() => {
        parseImageFromUrl()
    }, [props.src])

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file: any) => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    const handleDeleteFile = (e) => {
        if (props.isEditable) {
            setFiles([])
            if (props.handleDelete) {
                props.handleDelete(null)
            }
        }
    }

    const handleEdit = (e) => {
        if (props.isEditable) {
            open()
        }
    }

    const handleClickClose = (e) => {
        if (props.handleClickClose) {
            props.handleClickClose(e)
        }
    }

    const handleClickContainer = (e) => {
        if (props.multiple) {
            if (props.handleClickAddMultiple) {
                props.handleClickAddMultiple(e)
            }
        }
    }

    const show = { display: files.length == 0 || props.newMode ? 'flex' : 'none', justifyContent: "center" }

    return (
        <Container>
            {
                props.showCloseIcon &&
                <Fragment>
                    <CloseIcon onClick={handleClickClose} />
                    <InputSelected type="checkbox" onClick={props.handleClickSelected} checked={props.selectedValue}/>
                </Fragment>
            }

            <ImageWrapper {...props} onClick={handleClickContainer}>
                <ContainerDropzone style={show}
                    {...getRootProps({ className: "dropzone" })}>
                    {
                        !props.multiple &&
                        <input {...getInputProps()} />
                    }           
                    {
                        loading ?
                        <ContainerLoader>
                            <ScaleLoader color={color} loading={true}  css={`display: block;margin: 0 auto;`} />
                        </ContainerLoader>
                        : 
                        <AddNewIcon />
                    }
                </ContainerDropzone>
        

                {
                 
                    files.length > 0 && !props.newMode &&
                    (
                    loading ?
                      <ContainerLoader>
                        <ScaleLoader color={color} loading={true}  css={`display: block;margin: 0 auto;`} />
                      </ContainerLoader>
                        :
                         thumbs
                    )
            
                }
            </ImageWrapper >
            {
                <IconsContainer>
                    {                    
                        (files.length > 0 && !props.showCloseIcon && !props.newMode && !props.multiple) && 
                        <Fragment>
                            <EditIcon onClick={handleEdit} />
                            <span>|</span>
                            <DeleteIcon onClick={handleDeleteFile} />
                        </Fragment>
                    }
                </IconsContainer>
            }
            {
                props.messageError &&
                <ContainerMessage>
                    <Message>{props.messageError}</Message>
                </ContainerMessage>
            } 
        </Container>
    );
}

const ContainerLoader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

const AddNewIcon = styled(BsPlusCircleFill)`
    font-size: 1.2rem;
    color: #2196f3;
`


const EditIcon = styled(BsPencilSquare)`
    cursor: pointer;
`

const DeleteIcon = styled(BsTrash)`
    cursor: pointer;
`


const CloseIcon = styled(BsFillXCircleFill)`
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    display: none;
    font-size: 18px;
`

const InputSelected = styled.input`
    display: none;
    position: absolute;
    top: 5px;
    left: 0;
    z-index: 999;
`

const Container = styled.section`
    display: flex;
    flex-direction: column;
    max-width: 275px;
    max-height: 200px;
    position: relative;

    &&:hover ${CloseIcon}{
        display: block;
    }

    &&:hover ${InputSelected}{
        display: block;
    }
`

const ImageWrapper = styled.div`
    width: ${props => props.width ? props.width : '235px'};
    height:${props => props.height ? props.height : '180px'};
    margin: 5px auto;
    padding: 0.3rem;
    border: 1px dashed #D4D7DC;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    &&:hover{
        border-color: #2196f3;
    }
`


const IconsContainer = styled.section`
    display: flex;
    margin: 1px auto;
    justify-content: space-between;
    align-items: center;
    width:80px;
`
const IconsContainerAdd = styled.section`
    display: flex;
    margin: 1px auto;
    justify-content: center;
    align-items: center;
    width:90%;
`
const ContainerDropzone = styled.div`
    display: flex;
    height:100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s;
    cursor: pointer;
    &:hover{
        border-color: #2196f3;
    }
`

const ImageThumb = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    object-fit:contain;
    width: 100%;
    height: 100%;
    padding: 4px;
    box-sizing: border-box;
`

const Image = styled.img`
    display:block;
    /* height: 100%; */
    width:100%;
    max-width:100%;
    max-height:100%;
    margin-left: 0;
    margin-right: 0;
    object-fit: contain;
`

const ContainerMessage = styled.div`
    display: flex;
    justify-content: center;
`

const Message = styled.small`
    font-size: 12px;
    color: red;
`

