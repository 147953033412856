import React from 'react';
import { TotalProps } from '../../../models/bootstrap/ITotalProps';
import '../../../assets/bootstrap/css/total.css';

const Total: React.FC<TotalProps> = (props) => {
    const locale = props.culture ? props.culture : navigator.language;
    return <div className="total__container">
                <span>{props.label}:</span>
                <span>{props.currencySymbol ? props.currencySymbol : ""}{new Intl.NumberFormat(locale, { style: 'decimal'}).format(props.amount)}</span>
           </div>
}


export default Total