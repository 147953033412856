import React, { Fragment, useState } from 'react'
import { InputPassProps } from "../../../models/bootstrap/IInputPassProps"
import '../../../assets/bootstrap/css/input.css'
import "bootstrap-icons/font/bootstrap-icons.css";

const InputPass: React.FC<InputPassProps> = (props) =>{
   
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }
    let isEditable =
        props.isEditable !== null && props.isEditable !== undefined
            ? props.isEditable
            : true

    return (
        <div className="form__group">
            {isEditable ? (
                <input
                    type={showPassword ? 'text' : 'password'}
                    ref={props.setRef}
                    required={props.requiredField}
                    className={
                        props.className ? props.className : "form__input"
                    }
                    onInput={props.onChange} //en el onInput ayuda a que el state si cambie, algo asi.
                    //onChange={props.onChange}
                    onBlur={props.onBlur}
                    onKeyPress={props.onKeyPress}
                    name={props.name}
                    id={props.id}
                    value={props.value as string}
                    onChange={props.onChange}
                    defaultValue={props.defaultValue}
                />
            ) : (
                    <input
                        ref={props.setRef}
                        required={props.requiredField}
                        className={props.className ? `${props.className } form__input form__readonly` :  `form__input form__readonly`}
                        name={props.name}
                        id={props.id}
                        value={props.value as string}
                        defaultValue={props.defaultValue}
                        disabled
                    />
                )}
            <label htmlFor={props.id} className="form__label">
                {props.label}
            </label>
            {props.Icon?
                <div className={`styleIcon`}>
                    <span onClick={handleClickShowPassword} className={showPassword ? `bi bi-eye`: `bi bi-eye-slash`} style={{cursor:'pointer'}}></span>
                </div>
                :<i></i>
            }
            <div
                className="valid-feedback"
                data-label={props.label + "feedOK"}
            ></div>
            <div
                className="invalid-feedback"
                data-label={props.label + "feedError"}
            ></div>
        </div>
    )
}

export default InputPass
