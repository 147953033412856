import React,{ Fragment, useEffect, useState} from 'react'
import { DropdownButtonProps, OptionsDropdown } from '../../../models/bootstrap/IDropdownButtonProps'
import styled from 'styled-components'


export function DropdownButton(props: DropdownButtonProps) {
    const btnStyle = `btn__${props.size || 'md'} btn__${props.color ? props.color : "primary"}`
    const[options, setOptions ] = useState<OptionsDropdown[]>([])
    let beforeGroup: string = ""
    function sortList(a, b){
        if(props.orderBy === "DESC"){
            if(a.group < b.group) return 1
            if(a.group > b.group) return -1
        }
        else {
            if(a.group < b.group) return -1
            if(a.group > b.group) return 1
        }
        return 0
    }

    useEffect(() => {
        const newOptions = props.options.sort(sortList)
        setOptions([...newOptions])
    },[props.options, props.orderBy])
    return (
        <div className="dropdown show">
            <Button className={`${btnStyle} dropdown-toggle`} href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {props.label}
            </Button>

            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                {

                    options.map((x,index) => {
                        if(index > 0 && x.group != beforeGroup){
                            beforeGroup = x.group
                            return(
                                <Fragment>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" style={{cursor: 'pointer'}} onClick={x.onClick} >{x.label}</a>
                           
                                </Fragment>
                            )
                        }
                        beforeGroup = x.group
                        return  <a className="dropdown-item" style={{cursor: 'pointer'}} onClick={x.onClick} >{x.label}</a>                 
                    })
                }
            </div>
        </div>
    )
}

const Button = styled.a`
    &&:hover{
        color: #fff;
    }
`


