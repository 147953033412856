


class Utils{
    public colors: string[] = ["primary","secondary", "orange","blue","green", "default"]

    public generateQuickGuid(): string  {
        return (
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15)
        )
    }
}

export default new Utils();