import React, { useEffect, Fragment, useState } from "react"
import { DatePickerProps } from "../../../models/bootstrap/IDatePickerProps"
import moment from "moment"
import DatePickerReact from "react-datepicker"
import  { registerLocale, setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import '../../../assets/bootstrap/css/datepicker.css'

const DatePicker: React.FC<DatePickerProps> = (props) => {
    const[locale, setLocale] = useState("en-US")
    const[dateFormat, setDateFormat] = useState("dd/MMM/yyyy")
    const _language = props.language || 'en-US'
    const dateFormatCulture = {
        en: "MM/dd/yyyy",
        "en-US": "dd/MMM/yyyy",
        fr: "dd/MM/yyyy",
        de: "dd.MM.yyyy",
        it: "dd/MM/yyyy"

    }

    useEffect(() => {
        const getLocale = locale => require(`date-fns/locale/${locale}/index.js`)
        const _locale = getLocale(_language)
        registerLocale(_language,_locale)
        setDefaultLocale(_locale)
        setLocale(_locale)
        setDateFormat(dateFormatCulture[_language])
    },[props.language])

    function parseDate(date){
        try {
            const parseMoment =  moment(date)
            if(parseMoment.isValid()){
                return parseMoment.toDate()
            }
            return new Date()
        } catch (error) {
            return new Date()
        }
    }

    return (
        <div>
            {props.isEditable ? (
                <Fragment>
                    <div className="form__group" >
                    <DatePickerReact
                            className="form__input react-datepicker__input__cursor"
                            selected={parseDate(props.value)}
                            locale={locale}
                            dateFormat={dateFormat}
                            monthsShown={props.monthsShown}
                            showQuarterYearPicker={props.showQuarterYearPicker}
                            showPreviousMonths={props.showPreviousMonths}
                            isClearable={props.isClearable}
                            showWeekNumbers={props.showWeekNumbers}
                            // showTimeSelect={props.showTimeSelect}
                            withPortal={props.withPortal}
                            disabledKeyboardNavigation={props.disabledKeyboardNavigation}
                            disabled={props.disabled}
                            onSelect={(date) => props.onSelect && props.onSelect(moment(date))}
                            onChange={(date) => props.onChange && props.onChange(moment(date))}
                             />
                        <label className="form__label">
                            {props.label}
                            {props.required ? <div className="required">*</div> : ""}
                            {props.messageError ? (
                                <div style={{ fontFamily: "cursive" }} className="required">
                                    {props.messageError}
                                </div>
                            ) : (
                                ""
                            )}
                        </label>
                    </div>
                </Fragment>
            ) : (
                <div className="form__group" style={{ height: "47px", width: "100%" }}>
                    <input
                        type="text"
                        ref={props.ref}
                        className={  `form__input_readonly form__readonly` }
                        name={props.name}
                        required={props.requiredField}
                        id={props.id}
                        defaultValue={props.value}
                        readOnly
                    />
                    <label htmlFor={props.id} className="form__label__readonly">
                        {props.label}
                        {props.required ? <div className="required">*</div> : ""}
                        {props.messageError ? (
                            <div className="required">
                                {props.messageError}
                            </div>
                        ) : (
                            ""
                        )}
                    </label>
                </div>
            )}
        </div>
    )
}

export default DatePicker

