import React from 'react';
import { CardDonutSupplierProps } from '../../../models/bootstrap/ICardDonutSupplier';
import ChartDonut from '../DonutChart';
import '../../../assets/bootstrap/css/cardDonutSupplier.css';


const CardDonutSupplierTop: React.FC<CardDonutSupplierProps> = (props) => {
    return <div className="donut__supplier">
        <div className="donut__supplier-chart">
            <ChartDonut
                width="200"
                height="130"
                {...props} />
        </div>
        <section className="donut__supplier__detail">
            <p className="donut__supplier__detail-name">{props.supplierName}</p>
            <section className="donut__supplier__detail__summary">
                <p className="donut__supplier__detail__summary-title">{props.transactionLabel}:</p>
                <p className="donut__supplier__detail__summary-value">{props.transactionsValue}</p>
            </section>
            <section className="donut__supplier__detail__summary">
                <p className="donut__supplier__detail__summary-title">{props.billedAmountLabel}:</p>
                <p className="donut__supplier__detail__summary-value">{props.billedAmountValue}</p>
            </section>
        </section>
    </div>
}

export default CardDonutSupplierTop