import React, { useEffect, useRef } from 'react'
import { ChartDonutProps } from '../../../models/bootstrap/IChartDonutProps'
import Chart from 'chart.js'
// import ChartDataLabels from 'chartjs-plugin-datalabels';
const DonutChart: React.FC<ChartDonutProps> = (props) => {
  const canvas: React.LegacyRef<HTMLCanvasElement | any> = useRef(undefined);

  const drawText = () => {
    const originalLineDraw = Chart.controllers.doughnut.prototype.draw;
    Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
      draw: function () {
        originalLineDraw.apply(this, arguments);
        let width = this.chart.chart.width,
          height = this.chart.chart.height,
          titleHeight = this.chart.titleBlock.height,
          legendHeight = this.chart.legend.height;

        let fontSize = (height / 114).toFixed(2);
        let ctx = this.chart.chart.ctx;
        ctx.font = fontSize + "em Verdana";
        ctx.textBaseline = "middle";
        let text = ""
        let total = 0;
        const data = this.getMeta().controller._data
      
        if (data.length > 0) {
          if (data[1] == 100) {
            total = 0;
          } else {
            // const maxVal = data.reduce(function (p, v) {
            //   return (p > v ? p : v);
            // });

            // const minVal = data.reduce(function (p, v) {
            //   return (p < v ? p : v);
            // });
            total = data[0];
          }
        }

        if (total < 0) {
          total = total * -1;
        }
        const primaryColor = this.getMeta().controller._config.backgroundColor[0]
        // console.log(total)
        // if(this.chart.id == 3){             
        //     text = "25" + "%"
        //     ctx.fillStyle = '#fff'
        //     ctx.clearRect(80,70,70,30)       
        // }else{
        text = total + "%"
        //}
        let textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = (height + titleHeight + legendHeight) / 2;
        ctx.fillStyle = primaryColor;
        ctx.fillText(text, textX, textY);
      }
    });
  }

  useEffect(() => {
    drawText();
    //  Chart.plugins.unregister(ChartDataLabels);
    const config = {
      type: 'doughnut',
      data: {
        percentageInnerCutout: 80,
        cutoutPercentage: 20,
        datasets: [{
          labelX: props.data.length > 0 ? props.data[0] : '',
          labelY: props.data.length > 0 ? props.data[1] : '',
          data: props.data,
          backgroundColor: [
            props.colorOne,
            props.colorTwo,
          ],
          label: 'Status'
        }],
        labels: []
      },
      options: {
        plugins: {
          datalabels: {
            align: 'start',
            anchor: 'end',
            color: function () {
              return "#FFF"//context.dataset.backgroundColor;
            },
            font: function (context) {
              var w = context.chart.width;
              return {
                size: w < 512 ? 12 : 14,
                weight: 'bold',
              };
            },
            formatter: function (value, context) {
              const percent = props.showLabel ? `${context.chart.data.datasets[0].data[context.dataIndex]}%` : "";
              return percent;
            }
          }
        },
        tooltips: {
          custom: function (tooltip) {
            if (tooltip.opacity > 0) {
              //   props.refTitle.style.display = 'none'
            } else {
              // props.refTitle.style.display = 'block'
            }
          },
          enabled: true,
          mode: 'index',

          intersect: false,
          callbacks: {
            title: function () {
              return "Total"// data.datasets[tooltipItem[0].datasetIndex].labelTitle; 
            },
            label: function (tooltipItems, data) {
              let title: any = ""
              if (tooltipItems.datasetIndex == 0) {
                if (tooltipItems.index == 0) {
                  title = data.datasets[tooltipItems.datasetIndex].data.length > 0 ? data.datasets[tooltipItems.datasetIndex].data[0] : ''
                  title = title + '%'
                } else {
                  title = data.datasets[tooltipItems.datasetIndex].data.length > 0 ? data.datasets[tooltipItems.datasetIndex].data[1] : ''
                  title = title + '%'
                }
              } //data.datasets[tooltipItems.datasetIndex].label

              return title
            },
            footer: function () { return "..."; }
          },
          backgroundColor: '#F9F9F9',
          titleFontSize: 16,
          titleFontColor: '#0066ff',
          bodyFontColor: '#000',
          bodyFontSize: 14,
          displayColors: false,
          titleFontFamily: 'Helvetica'

        },
        cutoutPercentage: 75,
        responsive: true,
        legend: {
          position: 'bottom',
          display: false
        },
        title: {
          display: false,
          text: ''
        },
        animation: {
          animateScale: true,
          animateRotate: true
        }
      }
    }
    const chart = new Chart(canvas.current.getContext('2d'), config);
    if (props.setRefChart) {
      props.setRefChart(chart);
    }
    return () => chart.destroy();
  }, [])

  return <canvas ref={canvas} width={props.width} height={props.height} className="chartjs"></canvas>

}

export default DonutChart