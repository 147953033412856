import React from "react"
import { InputEmailProps } from "../../../models/bootstrap/IInputEmailProps"
import '../../../assets/bootstrap/css/input.css'

const InputEmail: React.FC<InputEmailProps> = (props) => {

    return (
        <div className="form__group" style={props.style}>
            <input
                type="email"
                ref={props.setRef}
                className={props.className ? props.className : "form__input"}
                required={props.requiredField}
                onInput={props.onChange}
                onChange={props.onChange}
                name={props.name}
                id={props.id}
                value={props.value}
                defaultValue={props.defaultValue}
            />
             <label htmlFor={props.id} className="form__label">
                {props.label}
            </label>
            <div
                className="valid-feedback"
                data-label={props.label + "feedOK"}
            ></div>
            <div
                className="invalid-feedback"
                data-label={props.label + "feedError"}
            ></div>
        </div>
    )
}

export default InputEmail
