import React from 'react'
import Utils from '../../../utils'
import '../../../assets/bootstrap/css/radioButton.css'
import { RadioButtonProps } from '../../../models/bootstrap/IRadioButtonProps';
import styled from 'styled-components'


const RadioButton: React.FC<RadioButtonProps> = (props) => {
   function VerticalItems(){
        return(
            <ContainerVertical>
                <label className="label-control">{props.Title}</label>
                {
                      props.Items.map((x, index) => {
                        const reference = Utils.generateQuickGuid()
                        return (
                            (props.isEditable) 
                            ?
                                (x.value === true) 
                                ? 
                                    <ContainerRadio className="custom-control custom-radio" key={reference} >
                                        <input className="custom-control-input" type="radio" key={x.Key + '-' + index} id={x.Key} name={props.name} defaultChecked onClick={props.handleChange} />
                                        <label htmlFor={x.Key} className="custom-control-label">{x.Label}</label>
                                    </ContainerRadio>
                                :   
                                    <ContainerRadio className="custom-control custom-radio" key={reference} >
                                        <input className="custom-control-input" type="radio" key={x.Key + '-' + index} id={x.Key} name={props.name} onClick={props.handleChange} />
                                        <label htmlFor={x.Key} className="custom-control-label">{x.Label}</label>
                                    </ContainerRadio>
                            :
                                (x.value === true)
                                ?
                                    <ContainerRadio className="custom-control custom-radio" key={reference} >
                                        <input className="custom-control-input" type="radio" key={x.Key + '-' + index} id={x.Key} name={props.name} defaultChecked onClick={props.handleChange} disabled />
                                        <label htmlFor={x.Key} className="custom-control-label">{x.Label}</label>
                                    </ContainerRadio>
                                :
                                    <ContainerRadio className="custom-control custom-radio" key={reference} >
                                        <input className="custom-control-input" type="radio" key={x.Key + '-' + index} id={x.Key} name={props.name} onClick={props.handleChange} disabled />
                                        <label htmlFor={x.Key} className="custom-control-label">{x.Label}</label>
                                    </ContainerRadio>
                        )
                    })
                }
            </ContainerVertical>
        )
    }

    function HorizontalItems(){
        return(
        <ContainerHorizontal>
            <label className="label-control mr-3">{props.Title}</label>
           {
                props.Items.map((x, index) => {
                    const reference = Utils.generateQuickGuid()
                    return (
                        (props.isEditable) 
                        ?
                            (x.value === true) 
                            ? 
                                <ContainerRadio className="custom-control custom-radio" key={reference} >
                                    <input className="custom-control-input" type="radio" key={x.Key + '-' + index} id={x.Key} name={props.name} defaultChecked onClick={props.handleChange} />
                                    <label htmlFor={x.Key} className="custom-control-label">{x.Label}</label>
                                </ContainerRadio>
                            :   
                                <ContainerRadio className="custom-control custom-radio" key={reference} >
                                    <input className="custom-control-input" type="radio" key={x.Key + '-' + index} id={x.Key} name={props.name} onClick={props.handleChange} />
                                    <label htmlFor={x.Key} className="custom-control-label">{x.Label}</label>
                                </ContainerRadio>
                        :
                            (x.value === true)
                            ?
                                <ContainerRadio className="custom-control custom-radio" key={reference} >
                                    <input className="custom-control-input" type="radio" key={x.Key + '-' + index} id={x.Key} name={props.name} defaultChecked onClick={props.handleChange} disabled />
                                    <label htmlFor={x.Key} className="custom-control-label">{x.Label}</label>
                                </ContainerRadio>
                            :
                                <ContainerRadio className="custom-control custom-radio" key={reference} >
                                    <input className="custom-control-input" type="radio" key={x.Key + '-' + index} id={x.Key} name={props.name} onClick={props.handleChange} disabled />
                                    <label htmlFor={x.Key} className="custom-control-label">{x.Label}</label>
                                </ContainerRadio>
                    )
                })
          
           }
        </ContainerHorizontal>
          )
    }

    return (
        <div>
            {
                props.horizontal === true ?  HorizontalItems() :  VerticalItems()
            }    
        </div>
    )
}

const ContainerHorizontal = styled.div`
    display: flex;
    flex-direction:row;
`

const ContainerVertical = styled.div`
    display: flex;
    flex-direction:column;
`

const ContainerRadio = styled.div`
    margin-right: 1.3rem;
`

export default RadioButton
