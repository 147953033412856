import React, { useState, useEffect } from "react"
import '../../../assets/bootstrap/css/section.css'
import { SectionEditableProps } from "../../../models/bootstrap/ISectionEditableProps"
import Button from '../Button'

const SectionEditable: React.FC<SectionEditableProps> = (props) => {
    const [canView, setCanView] = useState(false) // action.includes('V'));
    const [canCreate, setCanCreate] = useState(false) // action.includes('V'));
    const [canUpdate, setCanUpdate] = useState(false) // action.includes('U'));
    const [canUpdateSave, setCanUpdateSave] = useState(true)
    const [collapse, setCollapse] = useState(
        props.Collapse ? props.Collapse : false
    )

    if (canUpdate) {
        if (props.CancelAction === null || props.CancelAction === undefined)
            throw new Error("You must send function for cancel action")

        if (props.SaveAction === null || props.SaveAction === undefined)
            throw new Error("You must send function for save action")

        if (props.UpdateAction === null && props.UpdateAction === undefined)
            throw new Error(
                "You must send function for update button actions when edit"
            )
    }

    useEffect(() => {
        async function getPermissions() {
            //TODO: buscar los permisos y aplicar
            const action = props.Action ? props.Action.toUpperCase() : "";
            if (action.includes("UPDATE")) {
                setCanView(true)
                setCanUpdate(true)
                setCanCreate(false)
            } else if (action.includes("VIEW")) {
                setCanView(true)
            } else if (action.includes("CREATE")) {
                setCanView(true)
                setCanCreate(true)
                setCanUpdateSave(false)
            } else {
                setCanView(false)
                setCanUpdate(false)
                setCanCreate(false)
            }
        }
        getPermissions()
    }, [props])

    const allowSaveShow = () => {
        setCanUpdateSave(!canUpdateSave)
    }

    const allowViewShow = () => {
        setCollapse(!collapse)
    }

    const allowUpdateButton = (e: any) => {
        props.UpdateAction(e)
        allowSaveShow()
    }

    const CancelAction = (e: any) => {
        props.CancelAction(e)
        allowSaveShow()
    }

    const SaveAction = (e: any) => {
        props.SaveAction(e)
        allowSaveShow()
    }

    return (
        <div>
            {canView ? (
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* Default box */}
                                <div
                                    className={
                                        collapse
                                            ? "card collapsed-card card-transparent"
                                            : "card card-transparent"
                                    }>
                                    <div className="card-header">
                                        <h3 className="card-title card__title_align--left">
                                            {props.label}
                                        </h3>
                                        <div className="card-tools">
                                            {canView && collapse && (
                                                <button
                                                    type="button"
                                                    className="btn btn-tool"
                                                    data-card-widget="collapse"
                                                    data-toggle="tooltip"
                                                    title={props.labelEdit}
                                                    onClick={allowViewShow}>
                                                    <i className="fa fa-eye" />
                                                </button>
                                            )}

                                            {canUpdate || canCreate ? (
                                                canUpdateSave ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-tool"
                                                        data-toggle="tooltip"
                                                        title={props.labelEdit}
                                                        onClick={allowUpdateButton}
                                                    >
                                                        <i className="fa fa-pen" />
                                                    </button>
                                                ) : (
                                                    <div className="container__buttons--section">
                                                       <Button
                                                                id="Cancel"
                                                                name="Cancel"
                                                                label={props.lblbtnCancel ? props.lblbtnCancel : "Cancel"}
                                                                className="ml-1"
                                                                onClick={CancelAction}
                                                                type="button"
                                                                size="md"
                                                                color="secondary"
                                                                outline={true}
                                                            />
                                                        {
                                                           
                                                            <Button
                                                            id="Save"
                                                            name="Save"
                                                            label={props.lblbtnSave ? props.lblbtnSave : "Save"}
                                                            className="ml-1"
                                                            onClick={SaveAction}
                                                            type="primary"
                                                            size="md"
                                                            color="primary"
                                                            outline={true}
                                                        />
                                                        }
                                                    </div>
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {props.children}
                                    </div>
                                    {/* /.card-body */}
                                </div>
                                {/* /.card */}
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                ""
            )}
        </div>
    )
}


export default SectionEditable;
