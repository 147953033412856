import React from 'react'
import { LoaderProps } from '../../../models/bootstrap/ILoaderProps';
// import loading from "../../../assets/bootstrap/img/preloader.gif"



const Loader: React.FC<LoaderProps> = props => {
    const styles: any = props.style ? {...props.style} : { };
    if(props.center){
        styles["textAlign"] = "center";
    }
    return (
        <div style={styles}>
            <img
                src={props.src ? props.src : "https://cdn-xsalesconsumer.s3.us-east-2.amazonaws.com/console/preloader.gif"}
                style={props.style}
                alt="Loading gif"
            />
        </div>
    )
}



export default Loader;



