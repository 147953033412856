﻿import React,{useState,useEffect} from "react"
import { InputCurrencyProps } from "../../../models/bootstrap/IInputCurrency"
import NumberFormat from 'react-number-format';

const InputCurrency: React.FC<InputCurrencyProps> = (props) =>{

const[valueFormatear, setValueFormatear] = useState("")
const[valueNumber, setValueNumber] = useState("")


useEffect(() => {

  setValueFormatear(props.value)
  document.getElementById("div-T"+props.id)!.style.display = "none" ;
},[]);

useEffect(() => {

  setValueFormatear(props.value)
},[props.value]);

const handleOnFocus = () =>
{
  setValueNumber(valueFormatear)
  document.getElementById("div-"+props.id)!.style.display = "none";
  document.getElementById("div-T"+props.id)!.style.display = "block";
}

const handleOnBlur = (e) =>
{

  setValueFormatear( valueNumber)
  document.getElementById("div-T"+props.id)!.style.display = "none" ;
  document.getElementById("div-"+props.id)!.style.display = "block";
}

const handleOnChange = (e) =>
{
  setValueNumber(e.floatValue)
  props.onChange(e.floatValue)
}



const formatterCurrency = (valueFormatear, currencySymbol = "USD", cultureInfo = "en-US") => {
const formatter = new Intl.NumberFormat(cultureInfo, {
      style: 'currency',
      currency: currencySymbol,
      maximumFractionDigits : props.decimalScale
  });

  return formatter.format(valueFormatear);
}


  return (

    <div>


        <div id= {"div-"+props.id} className="form__group" style={props.style}>

          <input
            ref={props.setRef}
            id={props.id}
            type="text"
            className={props.className ? props.className : "form__input"}
            name={props.name}
            value={formatterCurrency(valueFormatear,props.currencySymbol, props.cultureInfo)}
            onFocus={handleOnFocus}
          />

        <label htmlFor={props.id} className="form__label">
                    {props.label}
                    {props.requiredField ? <div className="required">*</div> : ""}
                    {props.messageError ? (
                        <div className="required">
                            {props.messageError}
                        </div>
                    ) : (
                            ""
                        )}
        </label>


        </div>

        <div id={"div-T"+props.id} style={props.style} className="form__group">
        <NumberFormat
                     thousandSeparator={props.thousandSeparator? props.thousandSeparator: "," }
                     decimalSeparator={props.decimalSeparator ? props.decimalSeparator : "."}
                     className={props.className ? props.className : "form__input"}
                     required={props.requiredField}
                     ref={props.setRef}
                     maxLength={props.maxLength}
                     name={props.name}
                     readOnly={!props.isEditable}
                     decimalScale={props.decimalScale ? props.decimalScale : 2}
                     id={props.id+"Text"}
                     value={valueNumber as string}
                     onBlur={handleOnBlur}
                     onValueChange={(e) =>handleOnChange(e)}
        />


        <label htmlFor={props.id+"Text"} className="form__label">
                    {props.label}
                    {props.requiredField ? <div className="required">*</div> : ""}
                    {props.messageError ? (
                        <div className="required">
                            {props.messageError}
                        </div>
                    ) : (
                            ""
                        )}
        </label>
        </div>


</div>

  )
}
export default InputCurrency

