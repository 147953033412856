import React, { useState, useEffect, Fragment } from 'react'
import { Alert } from 'react-bootstrap'
import { MessageProps } from '../../../models/bootstrap/IMessageProps'
import styled from 'styled-components'


export function AlertMessage(props: MessageProps) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show)
    }, [props.show])

    function AlertMessage() {
        if (show) {
         return   <AlertStyled bsStyle={props.type} onDismiss={() => setShow(!show)}>
            {
                props.title &&
                <h5>{props.title}</h5>
            }
          
            <p>
               {props.message}
            </p>
            </AlertStyled>
        }
        return <Fragment></Fragment>
    }
    return  ( <AlertMessage /> );

}


const AlertStyled = styled(Alert)`
    background-color: #fff;
    color: inherit;
`