import React, { useEffect } from "react"
import { ModalProps } from '../../../models/bootstrap/IModalProps'
import Button from '../Button'
const Modal: React.FC<ModalProps> = (props) => {
    if (props.id === undefined || props.id === null) {
        throw new Error("You must indicate id property for Modal component")
    }

    if (props.title === undefined || props.title === null) {
        throw new Error("You must indicate title property for Modal component")
    }

    if (props.lblbtnCancel === undefined || props.lblbtnCancel === null) {
        throw new Error(
            "You must indicate lblbtnCancel property for Modal component"
        )
    }

    if (props.lblbtnSave === undefined || props.lblbtnSave === null) {
        throw new Error(
            "You must indicate lblbtnSave property for Modal component"
        )
    }

    if (props.handleCancel === undefined || props.handleCancel === null) {
        throw new Error(
            "You must indicate handleCancel method for Modal component"
        )
    }

    if (props.handleSave === undefined || props.handleSave === null) {
        throw new Error(
            "You must indicate handleSave method for Modal component"
        )
    }

    useEffect(() => {
        (window as any).$('.modal').on("hidden.bs.modal", function (e) { 
            if ((window as any).$('.modal:visible').length) { 
                (window as any).$('body').addClass('modal-open');
            }
        });
    },[])

    useEffect(() => {
        if (props.open == true) {
            (window as any).$(`#${props.id}`).modal("toggle")
        } else {
            (window as any).$(`#${props.id}`).modal("hide")
        }
        return () => {

        }
    }, [props.open])

    const handleCloseEvent = (e: any) => {
        if (props.handleCancel)
            props.handleCancel(e)

        if(props.withOutDismissClose){
            (window as any).$(`#${props.id}`).modal("hide")
        }
    }

    const handleSaveEvent = (e: any) => {
        if (props.handleSave)
            props.handleSave(e)
    }

    const classNameModal = props.size == "xl" ? `modal-dialog mw-100 w-75` : props.size == "lg" ? `modal-dialog modal-lg` : `modal-dialog`;

    return (
        <div
            className="modal fade"
            data-backdrop="static"
            id={props.id}
            tabIndex={-1}
            role="dialog"
        >
            <div className={classNameModal} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{props.title}</h4>
                    </div>
                    <div className="modal-body">{props.children}</div>
                    <div className="modal-footer">


                        {
                            props.withOutDismissClose ?
                                <Button
                                    id="btn-close-modal"
                                    label={props.lblbtnCancel}
                                    name={props.lblbtnCancel}
                                    size="md"
                                    color="default"
                                    type="button"
                                    outline={true}
                                    onClick={handleCloseEvent}
                                />
                                :
                                <Button
                                    id="btn-close-modal"
                                    label={props.lblbtnCancel}
                                    name={props.lblbtnCancel}
                                    size="md"
                                    color="default"
                                    type="button"
                                    outline={true}
                                    dataDismiss='modal'
                                    onClick={handleCloseEvent}
                                />
                        }



                        {(props.noAllowSave === undefined ||
                            props.noAllowSave === false) && (
                                // <button
                                //     type="button"
                                //     className="mb-2 mr-2 btn-transition btn btn-outline-primary bounds-button"
                                //     data-dismiss="modal"
                                //     onClick={handleSaveEvent}
                                // >
                                props.submitButton ?
                                    <Button
                                        id="btn-open-modal"
                                        label={props.lblbtnSave}
                                        name={props.lblbtnSave}
                                        size="md"
                                        color="primary"
                                        type="submit"
                                        form={props.formId}
                                        outline={false}
                                        onClick={handleSaveEvent}
                                    />
                                    :
                                    <Button
                                        id="btn-open-modal"
                                        label={props.lblbtnSave}
                                        name={props.lblbtnSave}
                                        size="md"
                                        color="primary"
                                        type="button"
                                        outline={false}
                                        dataDismiss='modal'
                                        onClick={handleSaveEvent}
                                    />

                                // </button>
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal
