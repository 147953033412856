import React from "react"
import { CardProps } from "../../../models/bootstrap/ICard"

const Card:React.FC<CardProps>  = (props) => {
    return (
        <div className="card-body login-card-body">
            <h3 className="login-box-msg title-pretty please-log-in">
                {props.label}
            </h3>
            {props.children}
        </div>
    )
}

export default Card
