import React, { useEffect, useRef } from 'react';
import "../../../assets/bootstrap/css/progressBar.css"
import { ProgressBarProps } from '../../../models/bootstrap/IProgressBarProps';


const ProgressBar:React.FC<ProgressBarProps> = (props) => {
    const ref = useRef<HTMLDivElement | null>(null)
    const styleWidth:any =  {}

    if(props.width){
        styleWidth.width = props.width;
    }

    useEffect(() => {
        if(ref.current){
            ref.current.style.setProperty("--width",props.completePercent);
        }
    })

    return <div style={styleWidth}>
            <div className="progress__bar" data-label="bar" ref={ref} style={styleWidth}></div>
            {
                props.showPercent &&
                 <span className="progress__bar__percent_text">{`${props.completePercent}%`}</span>
            }
                
            </div>
}

export default ProgressBar;