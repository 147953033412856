import React from 'react'
import { ToastifyContainerProps } from '../../../../src/models/bootstrap/ITotastifyProps'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

export function Toastify(props?: ToastifyContainerProps) {
  const config = {
    pauseOnFocusLoss: props?.pauseOnFocusLoss != undefined ? props?.pauseOnFocusLoss : false,
    progress: props?.progress != undefined ? props?.progress : undefined,
    position: props?.position != undefined ? props?.position : "top-right",
    autoClose: props?.autoClose != undefined ? props?.autoClose : 3000,
    hideProgressBar: props?.hideProgressBar != undefined ? props?.hideProgressBar : true,
    closeOnClick: props?.closeOnClick != undefined ? props?.closeOnClick : true,
    pauseOnHover: props?.pauseOnHover != undefined ? props?.pauseOnHover : true,
    draggable: props?.draggable != undefined ? props?.draggable : true
  }

  return (
    <ToastContainer
        newestOnTop={false}
        rtl={false}
        {...config}
    />
  )
}