import React from 'react'
import styled from 'styled-components'
import { TextMessageProps } from '../../../models/bootstrap/ITextMessage'


export function TextMessage(props: TextMessageProps) {
    return (
        <Message size={props.size}>{
          props.label}
          </Message>
    )
}

const Message = styled.p`
    color: var( --gray-dark-color);
    line-height:1.5;
    font-weight:normal;
    font-size: ${props => props.size ? props.size : "13px"};
`

