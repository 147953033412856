import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastifyProps, ToastifyTypes } from '../models/bootstrap/ITotastifyProps';



export function useToast(props?: ToastifyProps) {
  const configProps = {
    pending: props?.pendingMessage != undefined ? props?.pendingMessage : "Processing...",
    success: props?.successMessage != undefined ? props?.successMessage : "Success...",
    error: props?.errorMessage != undefined ? props?.errorMessage : "Error Processing...",
  }

  function notifyAsync(promise: Promise<any>) {
    toast.promise(promise,{...configProps})
  }

  function notify(type:ToastifyTypes, message: string) {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "warning":
        toast.warning(message);
        break;
      case "info":
        toast.info(message);
        break;
      case "error":
        toast.error(message);
        break;
      case "default":
        toast(message);
        break;
      default:
        toast(message);
        break;
    }
  }


  return { notify: notify, notifyAsync: notifyAsync }
}


